import React from "react"
import { useState, useEffect } from 'react';
import Router from 'next/router';

import Icon from 'react-icons-kit';
import { menu, x } from 'react-icons-kit/feather';

import onClickOutside from 'react-onclickoutside';

import { HamburgerMenuStrings } from '../../../../config/strings';
import theme from '../../../../config/theme';
import Options from './Options';
import Account from './Account';
import { GlobalContext } from "../../../../contexts/GlobalContext"


const HamburgerMenu = (props) => {
    const [open, setOpen] = useState(false);

    HamburgerMenu.handleClickOutside = () => setOpen(false);

    const openMenu = () => {
        setOpen(true)
    };

    const closeMenu = () => {
        setOpen(false)
    };

    useEffect(() => {
        Router.events.on('routeChangeStart', closeMenu);

        return () => {
            Router.events.off('routeChangeStart', closeMenu);
        }
    })

    return (<GlobalContext.Consumer>{
            (GlobalContext) => (<>
                <div className={`hamburger-menu-wrapper ${open ? 'open' : ''}`}>
                    <style jsx>{`
                .hamburger-menu-wrapper {
                    height: 100%;
                    align-items: center;
                    transition: all 0.1s ease;
                    transition-delay: 0.05s;
                    margin-left: 10px;
                    display: none;
                }

                .hamburger-menu {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;

                    transform: translate(-100%);
                    top: 0px;
                    left: 0px;
                    z-index: 10000;
                    color: ${theme.colors.hamburgerMenu.text};
                    background-color: ${theme.colors.hamburgerMenu.background};
                    transition: all 0.1s ease;
                    transition-delay: 0.05s;
                    display: flex;
                    flex-direction: column;
                    visibility: hidden;
                    border-top: 2px solid ${theme.colors.base.secondary};
                }

                .icon {
                    height: 50px;
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                }

                .header {
                    height: 50px;
                    background-color: ${theme.colors.hamburgerMenu.headerBackground};
                    color: ${theme.colors.hamburgerMenu.headerText};
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                }

                .hamburger-menu-wrapper.open > .icon {
                    background-color: ${theme.colors.base.secondary};
                }

                .label {
                    font-size: 1.4em;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-left: 10px;
                }

                @media only screen and (min-width: ${theme.responsive.m + 1}px) {
                    .hamburger-menu-wrapper {
                        position: relative;
                    }

                    .hamburger-menu {
                        margin-top: 50px;
                        position: absolute;
                        transform: translateY(0%);
                        height: auto;
                        width: 300px;
                        opacity: 0;
                    }

                    .hamburger-menu.open {
                        transform: translate(0);
                        opacity: 1;
                        visibility: visible;
                    }
                }

                @media only screen and (max-width: ${theme.responsive.m}px) {
                    .hamburger-menu-wrapper {
                        display: flex;
                    }
                    
                    .hamburger-menu.open {
                        transform: translate(0);
                        visibility: visible;
                        
                    }

                    .content {
                        height: calc(100% - 50px);
                    }

                    .end-content {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                    }
                }
            `}</style>
                    <div className="icon" onClick={openMenu}>
                        <Icon size={32} icon={menu} />
                    </div>
                    {open ? 
                        <div className={`hamburger-menu ${open ? 'open' : ''}`}>
                            <div className="header hide-gt-m">
                                <div className="icon" onClick={closeMenu}>
                                    <Icon size={32} icon={x} />
                                </div>
                                <div className="label">
                                    {HamburgerMenuStrings.headerLabel}
                                </div>
                            </div>
                            <div className="content">
                                <Options options={GlobalContext.categories}/>

                                <div className="hide-gt-m end-content">
                                    <Account />
                                </div>
                            </div>
                        </div> : null}
                </div>
            </>)}</GlobalContext.Consumer>
    )
}

HamburgerMenu.prototype = {};

const clickOutsideConfig = {
                handleClickOutside: () => HamburgerMenu.handleClickOutside
};

export default onClickOutside(HamburgerMenu, clickOutsideConfig);

