import React from "react"
import theme from "../../../../../config/theme";

const SearchOverlayHint = (props) => (
    <div className="search-overlay-hint" onClick={() => props.onSearch(props.children)}>
        <style jsx>{`
            .search-overlay-hint {
                margin: 10px 0;
                height: 48px;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding-left: 10px;
                box-sizing: border-box;
                border-bottom: 2px solid transparent;
                transition: all 0.1s ease;
            }

            .search-overlay-hint:hover, .search-overlay-result:active  {
                background-color: ${theme.colors.search.hoverItem};
            }
        `}</style>
        {props.children}
    </div>
)

export default SearchOverlayHint;