import Head from 'next/head';

import dayjs from "dayjs";
import "dayjs/locale/ro";
dayjs.locale('ro');

const LocalBusiness = () => ({
    "@context": "https://schema.org",
    "@id": "https://krit.ro/#organization",
    "@type": "LocalBusiness",
    "url": "https://krit.ro",
    "sameAs": [
        "https://www.facebook.com/KritStuff/",
    ],
    "logo": "https://krit.ro/img/logo.png",
    "address": {
        "@id": "https://krit.ro/#hqaddress",
        "@type": "PostalAddress",
        "streetAddress": "Str. Buna Vestire, Nr. 55",
        "addressLocality": "Ploiesti",
        "addressRegion": "PH",
        "postalCode": "100551",
        "addressCountry": "RO"
    },
    "name": "Krit.ro - Boardgame & Pop Culture Store",
    "email": "contact@krit.ro",
    "telephone": "+40 741 578 578",
    "url": "https://krit.ro",
    "location": {
        "@id": "https://krit.ro/#hqaddress"
    },
    "geo": {
        "@type": "GeoCoordinates",
        "latitude": "44.9355573",
        "longitude": "26.0366852"
    },
    "openingHoursSpecification": [
        {
            "@type": "OpeningHoursSpecification",
            "opens": "11:00:00",
            "closes": "19:00:00",
            "dayOfWeek": "http://schema.org/Tuesday"
        },
        {
            "@type": "OpeningHoursSpecification",
            "opens": "11:00:00",
            "closes": "19:00:00",
            "dayOfWeek": "http://schema.org/Wednesday"
        },
        {
            "@type": "OpeningHoursSpecification",
            "opens": "11:00:00",
            "closes": "19:00:00",
            "dayOfWeek": "http://schema.org/Thursday"
        },
        {
            "@type": "OpeningHoursSpecification",
            "opens": "11:00:00",
            "closes": "19:00:00",
            "dayOfWeek": "http://schema.org/Friday"
        },
        {
            "@type": "OpeningHoursSpecification",
            "opens": "11:00:00",
            "closes": "19:00:00",
            "dayOfWeek": "http://schema.org/Saturday"
        }
    ],
    "priceRange": "$"
})

const Product = (product) => ({
    "@context": "https://schema.org",
    "@type": "Product",
    "name": product.metaTitle,
    "image": product.images.map(image => `${process.env.imageStorage}/default_${image.image}.jpg`), 
    "description": product.metaDescription,
    "offers": {
        "@type": "Offer",
        "url": "https://krit.ro/produs/" + product.slug,
        "priceCurrency": "RON",
        "price": product.price,
        "priceValidUntil": dayjs().add(1, 'week').format('YYYY-MM-DD') ,
        "seller": {
            "@id": "https://krit.ro/#organization"
        }
    }
})

const Card = (product) => ({
    "@context": "https://schema.org",
    "@type": "Product",
    "name": product.metaTitle,
    "image": `${process.env.contentStorage}/card-images/${product.image}`,
    "description": product.name,
    "offers": {
        "@type": "Offer",
        "url": "https://krit.ro/single/" + product.id,
        "priceCurrency": "RON",
        "price": product.price,
        "priceValidUntil": dayjs().add(1, 'week').format('YYYY-MM-DD') ,
        "seller": {
            "@id": "https://krit.ro/#organization"
        }
    }
})

const Breadcrumbs = (breadcrumbs, endPath) => ({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [breadcrumbs.map((breadcrumb, i) => ({
        "@type": "ListItem",
        "position": i+1,
        "name": breadcrumb.label,
        "item": breadcrumb.as ? 'https://krit.ro' + breadcrumb.as : null
    }))]
})


const StructuredData = (props) => {
    let structuredDataContent = props.type === 'LocalBusiness' ? LocalBusiness() : 
                                props.type === 'Product' ? Product(props.product) :
                                props.type === 'Card' ? Card(props.product) : 
                                props.type === 'Breadcrumbs' ? Breadcrumbs(props.breadcrumbs, props.endPath) : null

    return (<Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={ { __html: JSON.stringify(structuredDataContent)}} />
    </Head>)
}

export default StructuredData;