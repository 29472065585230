import React from 'react'
import App from 'next/app'
import { initGA } from '../utils/analytics.js';

import {
    getSessionData
} from '../contexts/GlobalContext';

import GlobalProvider from '../contexts/GlobalProvider';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MainLayout from '../layouts/MainLayout';

import { Router } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { init } from '../utils/sentry'
import knl from '../utils/kanalytics';

init()

NProgress.configure({
    showSpinner: false,
    minimum: 0.4
})


export default class KritwareApp extends App {
    static async getInitialProps(appContext) {

        const appProps = await App.getInitialProps(appContext);

        const sessionData = await getSessionData(appContext.ctx);

        return {user: sessionData.user, cart: sessionData.cart ? sessionData.cart : null, categories: sessionData.categories, ...appProps};
    }

    componentDidMount() {
        initGA();

        Router.events.on('routeChangeStart', (url) => {
            knl.logEvent('route-change', {
                url
            })
            NProgress.start()
        });
        Router.events.on('routeChangeComplete', () => {
            knl.logEvent('route-change-complete', {
                ...arguments
            })
            NProgress.done();
        });
        Router.events.on('routeChangeError', () => {
            knl.logEvent('route-change-error', {
                ...arguments
            })
            NProgress.done()
        });
    }

    render() {
        const { Component, pageProps, err } = this.props


        if(this.props.router.asPath.indexOf('/landing/') === 0) {
            return <Component {...pageProps} err={err}/>
        }

        return (
            <GlobalProvider
                categories={this.props.categories}
                initialUser={this.props.user}
                initialCart={this.props.cart}>

                    <MainLayout {...pageProps}>

                        <Component {...pageProps} err={err}/>
                    </MainLayout>
            </GlobalProvider>
        )
    }
}
