import React from "react"
import Head from 'next/head'

const HeadSection = () => (
    <Head>
        <title>Krit.ro</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta charSet="utf-8" />
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"></meta>
        <meta name="google-site-verification" content="PhPOYVIhUR8pJOORjREQLSx8_kgMg4KiXo59DX7KoaQ" />
        <meta name="facebook-domain-verification" content="qivh8uds7m673d5256tyl8qf5hpinr" />
        {/* <meta property="og:image" content="https://krit.ro/img/og-logo.a.png"/> */}

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>

        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
    </Head>
)

export default HeadSection;
