import React from "react"
import theme from "../../../../config/theme"
import Icon from "react-icons-kit"
import { logOut } from "react-icons-kit/feather"
import { user } from "react-icons-kit/feather"
import { AccountStrings } from "../../../../config/strings"
import Button from "../../../elements/Base/Button"
import Link from "../../../elements/Base/Link"
import ButtonV2 from '../../../elements/Base/ButtonV2';

const AccountOverlay = (props) => (
    <div className="account-overlay">
        <style jsx>{`
            .account-overlay {
                height: 100%;
                background-color: ${theme.colors.popover.background};
                color: ${theme.colors.popover.text};
                border-top: 2px solid ${theme.colors.gradients.blueA};
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
                padding: 10px;
            }

            .account-overlay-info {
                font-weight: 700;
                padding: 10px;
            }

            .account-overlay-menu {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .menu-item {
                width: 200px;
                height: 48px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                cursor: pointer;
            }

            .menu-item:hover {
                background-color: ${theme.colors.base.lightGray};
            }

            .menu-item .label {
                margin-left: 10px;
            }

            .account-overlay-info {
                font-weight: 300;
                padding: 10px;
            }

            .account-overlay-controls { 
                display: flex;
                flex-direction: row;
                width: 300px;
            }

            .account-overlay-control {
                flex-grow: 1;
            }

            .red {
                color: red;
            }

            .gray {
                color: #888888;
            }
        `}</style>
        
        {
            props.authenticated ? 
            <>
                <div className="account-overlay-info">
                    Salut, {props.user.name}
                </div>

                <div className="account-overlay-menu">
                    <Link href="/cont/contul-meu">
                        <div className="menu-item">
                                <Icon size={20} icon={user}/>
                                <div className="label">Contul Meu</div>
                        </div>
                    </Link>
                    <div className="menu-item" onClick={props.onLogOut}>
                        <Icon size={20} icon={logOut}/>
                        <div className="label">Log out</div>
                    </div>
                </div>
            </> : <>
                <div className="account-overlay-info">
                    {AccountStrings.loginInvite}
                </div>
                <div className="account-overlay-controls">
                    <div className="account-overlay-control">
                        <ButtonV2 href="/cont/login" type="blue">
                            {AccountStrings.loginButton}
                        </ButtonV2>
                    </div>
                   
                    <div className="account-overlay-control">
                        <ButtonV2 href="/cont/inregistrare" 
                                type="primary" 
                                variant="transparent" 
                                padding={false} 
                                shadow={false}>
                            {AccountStrings.shortRegisterButton}
                        </ButtonV2>
                    </div>
                </div>
                <div className="account-overlay-info gray">
                    <span className="red">*</span> Nu ai nevoie de cont pentru a plasa o comanda!
                </div>
              
            </>
        }
       
    </div>
)

export default AccountOverlay;