import Router from "next/router";
import nookies from "nookies";
import React, { Component } from "react";
import { addToCartEvt, removeFromCartEvt } from "../utils/analytics";
import Api from "../utils/api";

import dayjs from "dayjs";
import "dayjs/locale/ro";
dayjs.locale("ro");

import { GlobalContext, getCart } from "./GlobalContext";

export default class GlobalProvider extends Component {
  state = {
    authenticated: false,
    user: null,
    session: null,
    cart: {
      cards: [],
      products: [],
      voucherError: false,
      voucherSuccess: false,
    },
    categories: this.props.categories,

    cartLoading: [],
    oneClickBuyLoading: [],
    oneClickBuyProducts: {},
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.initialUser || this.props.initialCart) {
      let newState = {};

      if (this.props.initialUser) {
        newState.user = this.props.initialUser;
        newState.authenticated = true;
      }

      if (this.props.initialCart) {
        newState.cart = this.props.initialCart;
      } else {
        newState.cart = {
          products: [],
        };
      }

      this.setState(newState);
    }

    Router.events.on("routeChangeComplete", (url, { shallow }) => {
      this.getCart();
    });
  }

  login = async (data, long = false, ctx = null) => {
    nookies.set(ctx, "session", data.token, {
      path: "/",
      maxAge: 3600 * 24 * 30,
      //maxAge: long ? process.env.longAuthLifespan : process.env.shortAuthLifespan,
    });

    const cart = await getCart();

    this.setState({
      cart: cart.data || { products: [] },
      authenticated: true,
      user: data.user,
    });

    Router.push("/").then(() => window.scrollTo(0, 0));
  };

  logout = async (ctx = null) => {
    nookies.destroy(ctx, "session", {
      path: "/",
    });

    const cart = await getCart();

    this.setState({
      cart: cart.data,
      authenticated: false,
      user: null,
    });

    Router.push("/").then(() => window.scrollTo(0, 0));
  };

  updateCart = async (
    product,
    quantity,
    type = "product",
    override = false
  ) => {
    // let cart = await this.getCart();

    // let exists = false;

    // if (!cart.products) {
    //     cart.products = [];
    // }

    // if(!cart.cards) {
    //     cart.cards = [];
    // }

    // if(type === 'product') {
    //     for (let i in cart.products) {
    //         if (cart.products[i].id === product) {
    //             if (override) {
    //                 cart.products[i].quantity = quantity;
    //             } else {
    //                 cart.products[i].quantity += quantity;
    //             }

    //             exists = true;
    //             break;
    //         }
    //     }

    //     if (!exists) {
    //         cart.products = cart.products || [];

    //         cart.products.push({
    //             id: product,
    //             quantity: quantity
    //         });
    //     }
    // } else if(type === 'card') {
    //     for (let i in cart.cards) {
    //         if(cart.cards[i].id === product) {
    //             if(override) {
    //                 cart.cards[i].quantity = quantity;
    //             } else {
    //                 cart.cards[i].quantity += quantity;
    //             }

    //             exists = true;
    //             break;
    //         }
    //     }

    //     if(!exists) {
    //         cart.cards = cart.cards || [];

    //         cart.cards.push({
    //             id: product,
    //             quantity: quantity
    //         })
    //     }
    // }

    // const newCart = await Api.post('/public/cart', {}, {}, cart);

    const newCart = await Api.patch(
      "/public/cart",
      {},
      {},
      {
        item: {
          id: product,
          quantity,
          type,
          override,
        },
      }
    );

    // nookies.set(null, "session", newCart.token, {
    //     path: "/",
    //     maxAge: 3600 * 24 * 30
    //     //maxAge: process.env.shortAuthLifespan
    // });

    this.setState({
      cart: newCart.data,
    });
  };

  addToCart = async (product, type = "product") => {
    await this.setState({
      cartLoading: [...this.state.cartLoading, `${type}-${product.id}`],
    });

    addToCartEvt(product);

    await this.updateCart(product.id, 1, type, false);

    this.setState({
      cartLoading: this.state.cartLoading.filter(
        (l) => l !== `${type}-${product.id}`
      ),
    });
  };

  oneClickBuy = async (product) => {
    const type = "product";
    await this.setState({
      oneClickBuyLoading: [
        ...this.state.oneClickBuyLoading,
        `${type}-${product.id}`,
      ],
    });

    addToCartEvt(product);

    const response = await Api.post(
      "/public/one-click-buy",
      {},
      {},
      {
        productId: product.id,
      }
    );

    this.setState({
      oneClickBuyLoading: this.state.oneClickBuyLoading.filter(
        (l) => l !== `${type}-${product.id}`
      ),
      oneClickBuyProducts: {
        ...this.state.oneClickBuyProducts,
        [`${type}-${product.id}`]: response && response.success,
      },
    });
  };

  setQuantity = (
    product,
    quantity,
    type = "product",
    mode = "add",
    override = false
  ) => {
    if (mode === "add") {
      addToCartEvt(product);
    } else {
      removeFromCartEvt(product, 1);
    }

    return this.updateCart(product.id, quantity, type, override);
  };

  isInCart = (product, type = "product") => {
    if (
      !this.state.cart.products &&
      type === "product" &&
      !this.state.cart.cards &&
      type === "card"
    ) {
      return 0;
    }

    if (type === "card") {
      const inCart = (this.state.cart.cards || []).find(
        (cartCard) => cartCard.id === product
      );
      return inCart ? inCart.quantity : 0;
    } else {
      const inCart = (this.state.cart.products || []).find(
        (cartProduct) => cartProduct.id === product
      );
      return inCart ? inCart.quantity : 0;
    }
  };

  globalCartLoading = (productId, type = "product") => {
    return this.state.cartLoading.indexOf(`${type}-${productId}`) !== -1;
  };

  globalOneClickBuyLoading = (productId, type = "product") => {
    return this.state.oneClickBuyLoading.indexOf(`${type}-${productId}`) !== -1;
  };

  globalOneClickBuyStatus = (productId) => {
    if (
      typeof this.state.oneClickBuyProducts["product-" + productId] ===
      "undefined"
    ) {
      return "not_actioned";
    } else {
      return !!this.state.oneClickBuyProducts["product-" + productId]
        ? "success"
        : "failed";
    }
  };

  cartInStock = () => {
    let inStock = true;

    for (let i in this.state.cart.products) {
      if (
        this.state.cart.products[i].quantity >
          this.state.cart.products[i].availableQuantity &&
        this.state.cart.products[i].quantity >
          this.state.cart.products[i].preorderQuantity
      ) {
        inStock = false;
      }
    }

    for (let i in this.state.cart.cards) {
      if (
        this.state.cart.cards[i].quantity >
        this.state.cart.cards[i].availableQuantity
      ) {
        inStock = false;
      }
    }

    for (let i in this.state.cart.vouchers) {
      let voucher = this.state.cart.vouchers[i];

      if (
        dayjs(voucher.validFrom).isAfter(dayjs()) ||
        dayjs(voucher.validTo).isBefore(dayjs())
      ) {
        inStock = false;
      }
    }

    return inStock;
  };

  cartHasPreorders = () => {
    let preorder = 0;

    for (let i in this.state.cart.products) {
      if (
        this.state.cart.products[i].availablePreorderQuantity > 0 &&
        this.state.cart.products[i].availableQuantity === 0
      ) {
        preorder = 1;
        break;
      }
    }

    return preorder;
  };

  removeFromCart = async (product, type = "product") => {
    removeFromCartEvt(product);

    await this.setState({
      cartLoading: [...this.state.cartLoading, `${type}-${product.id}`],
    });

    await this.updateCart(product.id, 0, type, true);

    this.setState({
      cartLoading: this.state.cartLoading.filter(
        (l) => l !== `${type}-${product.id}`
      ),
    });
  };

  clearCart = async () => {
    this.setState({
      cart: {},
    });
  };

  getCart = async () => {
    const cart = await Api.get("/public/cart");

    this.setState({
      cart: cart.data,
    });

    return cart.data;
  };

  applyVoucherCode = async (code) => {
    const newCart = await Api.post(
      "/public/cart/add-voucher",
      {},
      {},
      {
        code,
      }
    );

    this.setState({
      cart: {
        ...newCart.data,
        voucherError: newCart.success === false ? newCart.errorMessage : false,
        voucherSuccess: newCart.success === true,
      },
    });
  };

  removeVoucherCode = async (code) => {
    const newCart = await Api.post("/public/cart/remove-voucher");

    this.setState({
      cart: newCart.data,
    });
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          authenticated: this.state.authenticated,
          user: this.state.user,
          session: this.state.session,
          categories: this.state.categories,

          login: this.login,
          logout: this.logout,

          //Cart
          cart: this.state.cart,
          clearCart: this.clearCart,

          addToCart: this.addToCart,
          oneClickBuy: this.oneClickBuy,
          removeFromCart: this.removeFromCart,
          updateCart: this.updateCart,
          isInCart: this.isInCart,
          setQuantity: this.setQuantity,
          cartInStock: this.cartInStock,
          cartHasPreorders: this.cartHasPreorders,

          globalCartLoading: this.globalCartLoading,
          globalOneClickBuyLoading: this.globalOneClickBuyLoading,
          globalOneClickBuyStatus: this.globalOneClickBuyStatus,

          applyVoucherCode: this.applyVoucherCode,
          removeVoucherCode: this.removeVoucherCode,
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
