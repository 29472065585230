import React from "react"
import WidthWrapper from "../../elements/Base/WidthWrapper"
import dayjs from "dayjs";
import "dayjs/locale/ro";
dayjs.locale('ro');
import theme from "../../../config/theme"

const BottomFooter = () => {
    return (
        <div className="bottom-footer-wrapper">
            <style jsx>{`
                .bottom-footer-wrapper {
                    background-color: ${theme.colors.base.black};
                    color: ${theme.colors.base.lightGray};
                    font-size: ${theme.font.subtext};
                }

                .bottom-footer {
                    display: flex;
                    justify-content: space-between;
                    padding: 20px;
                }

                .links {
                    display: flex;
                }

                .bottom-link {
                    margin-left: 10px;
                }

                @media only screen and (max-width: ${theme.responsive.s}px) {
                    .bottom-footer {
                        flex-direction: column-reverse;
                    }

                    .links {
                        margin-bottom: 10px;
                        flex-direction: column;
                    }

                    .bottom-link {
                        margin-bottom: 10px;
                    }
                }

                .payment-gateway {
                    background-color: ${theme.colors.base.white};
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    height: 150px;
                    padding-top: 10px;
                    border-top: 2px solid ${theme.colors.base.secondary};
                }

                .payment-gateway img {
                    height: 100%;
                }

            `}</style>
            <div className="payment-gateway">
                <img src="/img/euplatesc.jpg" alt="EuPlatesc"/>
            </div>
            <WidthWrapper>
                <div className="bottom-footer">
                    <div className="copyright">
                        Copyright © KRIT ENTERTAINMENT SRL {dayjs().format('YYYY')}. Toate drepturile rezervate
                    </div>
                </div>
            </WidthWrapper>
        </div>
    )
}

export default BottomFooter;