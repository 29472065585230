
import React from "react"

const CardQuantityBadge = (props) => {
    return (
        <div>
            <style jsx>{`
                .badge-quantity {
                    font-size: 10px;
                    font-weight: bold;
                    background-color: #EAEAEA;
                    padding: 2px 4px;
                    margin: 0px 4px;
                    color: green;
                    margin-left: 10px;
                    border-radius: 24px;
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 0 1px #444444;
                }

            `}</style>

            <div className="badge badge-quantity">{props.product.quantity > 3 ? '3+' : props.product.quantity}</div>
        </div>
    )
}

export default CardQuantityBadge;
