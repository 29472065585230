import React from "react"
import ButtonV2 from "../../../elements/Base/ButtonV2";
import theme from "../../../../config/theme";

const SearchTypeSelector = (props) => (
    <div className="selector">
        <style jsx>{`
            .selector {
                display: flex;
                justify-content: stretch;
                background-color: #EEEEEE;
                border-radius: 4px;
                margin: 0 10px;
                margin-bottom: 10px;
                min-width: 11vw;
                overflow: hidden;
                ${props.view === 'desktop' ? `margin-top: 10px;`: ''}
            }

            .selector > div {
                flex-grow: 0.25;
                transition: all 0.2s;
            }

            .selector > div.active {
                flex-grow: 1;
            }

            @media only screen and (min-width: ${theme.responsive.m}px) {
                .selector > div {
                    max-width: 180px;
                }    
            }

        `}</style>

        
        <div className={props.mode === 'product' ? 'active' : ''}>   
            <ButtonV2 height={props.view === "desktop" ? 32 : 42} padding={false} rounded={false} type="blue" variant={`${props.mode === 'product' ? '' : 'transparent'}`} onClick={() => { props.onChangeMode('product') }}>Produse</ButtonV2>
        </div>
        <div className={props.mode === 'card' ? 'active' : ''}>   
            <ButtonV2 height={props.view === "desktop" ? 32 : 42} padding={false} rounded={false} type="dark-reverse" variant={`${props.mode === 'card' ? '' : 'transparent'}`} onClick={() => { props.onChangeMode('card') }}>Singles</ButtonV2>
        </div>      
    </div>
)

export default SearchTypeSelector;