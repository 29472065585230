import React from "react"
import { CartStrings } from "../../../../config/strings"
import Icon from "react-icons-kit"
import theme from "../../../../config/theme"
import { shoppingBasket } from "react-icons-kit/fa";
import Button from "../../../elements/Base/Button";
import { logEvent } from "../../../../utils/analytics";


const EmptyCart = (props) => {
    return (
        <div className="cart-overlay-info">
            <style jsx>{`    
                .cart-overlay-info {
                    font-weight: 300;
                    padding: 20px 10px;
                    height: 200px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.3em;
                    font-weight: 700;
                    color: ${theme.colors.base.darkGray};
                }

                .heading {
                    margin-top: 20px;
                }
            `}</style>

            <Icon size={72} icon={shoppingBasket}/>

            <div className="heading">
                {CartStrings.emptyCartText}
            </div>            

            {props.fullFeatured ? 
            <div className="back-button">
                <Button href="/" padding={true} shadow={false} onClick={() => {
                    logEvent("Cart", "Back")
                }}>
                    Înapoi la pagina principala
                </Button>
            </div> : null}
        </div>
    )
}

export default EmptyCart;