import React from "react"
import WidthWrapper from "../../elements/Base/WidthWrapper"
import Icon from "react-icons-kit"
import { mapPin } from "react-icons-kit/feather"
import { phone } from "react-icons-kit/feather"
import { mail } from "react-icons-kit/feather"
import theme from "../../../config/theme"
import Link from "../../elements/Base/Link"

const MiddleFooter = () => {
    return (
        <div className="middle-footer-wrapper">
            <style jsx>{`
                .middle-footer {
                    display: flex;
                    flex-direction: row;
                    padding: 20px;

                    color: ${theme.colors.base.base};
                }

                .column {
                    flex-grow: 1;
                    padding: 10px;
                }

                .title {
                    font-weight: 700;
                    font-size: ${theme.font.text};
                    margin-bottom: 20px;
                    text-transform: uppercase;
                }

                .item {
                    margin-bottom: 10px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                }

                .item span {
                    margin-left: 5px;
                }

                @media only screen and (max-width: ${theme.responsive.s}px) {
                    .middle-footer {
                        flex-direction: column;
                    }
                }
            `}</style>
            <WidthWrapper>
                <div className="middle-footer">
                    <div className="column">
                        <div className="title">
                            DATE CONTACT
                        </div>
                        <div className="item">
                            <a target="_blank" rel="noopener"
                                href="https://www.google.com/maps/place/Krit/@44.9355967,26.036745,17z/data=!3m1!4b1!4m5!3m4!1s0x40b249f150465b7f:0x9a574b4d3a1f31ae!8m2!3d44.9355929!4d26.038939">
                                <Icon size={20} icon={mapPin}/>
                                <span>
                                    Str. Buna Vestire, Nr. 55, Ploiești
                                </span>
                            </a>
                        </div>
                        <div className="item">
                            <a href="tel:+40741578578">
                                <Icon size={20} icon={phone}/>
                                <span>
                                    0741 578 578
                                </span>
                            </a>
                        </div>
                        <div className="item">
                            <a href="mailto:contact@krit.ro">
                                <Icon size={20} icon={mail}/>
                                <span>
                                    contact@krit.ro
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="column">
                        <div className="title">
                            Informații generale
                        </div>
                        <Link href="/info/contact">
                            <div className="item">
                                Contact
                            </div>
                        </Link>
                        
                        <Link href="/info/detalii-livrare">
                            <div className="item">
                                Livrare și plată
                            </div>
                        </Link>
                        
                        <Link href="/info/politica-retur">
                            <div className="item">
                                Politica Retur
                            </div>
                        </Link>
                    </div>
                    <div className="column">
                        <div className="title">
                            Informații legale
                        </div>
                        <Link href="/info/termeni-si-conditii">
                            <div className="item">
                                Termeni și Condiții
                            </div>
                        </Link>

                        <Link href="/info/politica-utilizare-cookies">
                            <div className="item">
                                Politica de utilizare Cookie-uri
                            </div>
                        </Link>
                        
                        <Link href="/info/politica-confidentialitate">
                            <div className="item">
                                Politica de Confidențialitate
                            </div>
                        </Link>

                        <Link href="https://anpc.ro/" external={1}>
                            <div className="item">
                                ANPC
                            </div>
                        </Link>
                    </div>
                </div>
            </WidthWrapper>
        </div>
    )
}

export default MiddleFooter;