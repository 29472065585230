import React, { useState, useEffect } from "react"
import theme from "../../../config/theme"
import Logo from '../../elements/Logo';
import WidthWrapper from "../../elements/Base/WidthWrapper";
import HamburgerMenu from "./HamburgerMenu";
import Search from "./Search";
import Account from "./Account";
import Cart from "./Cart";
import { withRouter, useRouter } from "next/router";


const modes = {
    '/cautare-singles/[slug]': 'card',
    '/singles': 'card',
    '/single/[id]': 'card'
}

const Header = (props) => {
    const router = useRouter()

    const [mode, setMode] = useState('product');

    useEffect(() => {
        setMode(modes[router.pathname] || 'product');
    });


    return (
        <div className="header-wrapper">
            <style jsx>{`
                .header-placeholder {
                    height: 60px;
                }

                .header-container {
                    background: linear-gradient(to right,${theme.colors.gradients.darkA} 10%,${theme.colors.gradients.darkB});
                    color: ${theme.colors.base.white};

                    position: fixed;
                    top: 0;
                    z-index: 1000;
                    width: 100vw;
                    height: 60px;
                    z-index: 10000;
                }

                .header {

                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    box-sizing: border-box;
                }

                .left-align {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    z-index: 9000;
                }

                .right-align {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    flex-grow: 1;
                    height: 100%;
                }

                @media only screen and (max-width: ${theme.responsive.s}px) {
                    .header-container {
                        height: 50px;
                    }

                    .header-placeholder {
                        height: 50px;
                    }
                }

            `}</style>
            <div className="header-placeholder">

            </div>
            <div className="header-container">
                <WidthWrapper height={"100%"}>
                    <div className="header">
                        <div className="left-align">
                            <HamburgerMenu/>
                            <Logo width={200}/>
                        </div>
                        <div className="right-align">
                            <Search expanded={true} mode={mode}/>
                            <Account/>
                            <Cart/>
                        </div>
                    </div>
                </WidthWrapper>
            </div>
        </div>
    )
}

export default withRouter(Header);
