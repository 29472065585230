import React from "react"
import Option from "./Option";

const Options = (props) => (
    <div className="options-wrapper">
        <Option as="/toate-produsele?sort=discount&filters=filterAvailability_Oferte" href="/toate-produsele?sort=discount&filters=filterAvailability_Oferte">
            Oferte
        </Option>
        <Option as="/toate-produsele" href="/toate-produsele">
            Toate Produsele
        </Option>
        {props.options.map(option => (
            <Option href="/categorie/[slug]" as={`/categorie/${option.slug}`} key={'hamburger-option-' + option.slug}>
                {option.title}
            </Option>
        ))}
        <Option as="/singles" href="/singles">
            Carti TCG (Singles)
        </Option>
    </div>
)

export default Options;
