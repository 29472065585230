import React from "react"
import WidthWrapper from '../../elements/Base/WidthWrapper';
import Logo from '../../elements/Logo';
import theme from '../../../config/theme';
import { FooterStrings } from '../../../config/strings';

const TopFooter = () => {
    return (
        <div className="top-footer-wrapper">
            <style jsx>{`
                .top-footer-wrapper {
                    background-color: ${theme.colors.base.base};
                    color: ${theme.colors.base.white};
                }

                .top-footer {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                }

                .text-content {
                    flex-grow: 1;
                    margin-left: 10px;
                    
                }

                @media only screen and (max-width: ${theme.responsive.s}px) {
                    .top-footer {
                        flex-direction: column;
                        align-items: center;
                    }

                    .text-content {
                        display: none;
                    }
                }
            `}</style>
            <WidthWrapper>
                <div className="top-footer">
                    <Logo size="l"/>
                    <div className="text-content">
                        {FooterStrings.companyDescription}
                    </div>
                </div>
            </WidthWrapper>
        </div>
    )
}

export default TopFooter;