import React from "react"
const WidthWrapper = (props) => (
    <div className="width-wrapper-container">
        <style jsx>{`
            .width-wrapper-container {
                width: 100%;
                display:flex;
                justify-content: center;
                ${props.height ? `height: ${props.height};` : ``}
            }

            .width-wrapper {
                width: 100%;
                max-width: 1800px;
            }

            .width-wrapper.small {
                max-width: 1440px;
            }

            .width-wrapper.xsmall {
                max-width: 1024px;
                border: 1px solid #DDDDDD;
                margin-top: 20px;
                box-shadow: 0px 0px 10px 0px #DDDDDD;
            }
        `}</style>
        <div className={`width-wrapper ${props.className}`}>
            {props.children}
        </div>
    </div>
)

export default WidthWrapper;