import React, { useEffect } from "react"
import theme from "../../../config/theme";

const GlobalCSS = () => {

    return (
        <style jsx global>{`
            * {
                -webkit-tap-highlight-color: transparent;
            }

            html {
                height: 100%;
                font-size: ${theme.font.text};
                overflow-x: hidden;
                overflow-y: scroll;
            }

            input {
                font-size: ${theme.font.text};
            }

            body {
                font-family: 'Montserrat', 'Helvetica';
                letter-spacing: 0.25px;
                word-spacing: 0.6px;
                background-color: ${theme.colors.body.background};
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
            }

            button {
                font-family: 'Montserrat', 'Helvetica';
            }

            input {
                font-family: 'Montserrat', 'Helvetica';
            }
    

            #__next {
                height: 100%;
            }

            a {
                color: inherit;
                text-decoration: none;
            }

            #nprogress .bar, #nprogress .spinner {
                z-index:10000;
            }

            #nprogress .bar {
                background: linear-gradient(to right,${theme.colors.gradients.blueA} 10%,${theme.colors.gradients.blueB});
                height: 5px;
            }

            #nprogress .peg {
                background: linear-gradient(to right,${theme.colors.gradients.blueA} 10%,${theme.colors.gradients.blueB});
            }
    

            .hide {
                display: none;
            }

            .flex {
                display: flex;
            }       
        `}</style>
    )
}

export default GlobalCSS;
