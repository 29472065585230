export default {
    headerLoginButton: "Login",
    headerAccountButton: "Contul meu",

    loginInvite: "Intră în contul tău KRIT.ro",

    loginPageTitle: "Intră în contul tău KRIT.ro",
    registerPageTitle: "Cont nou",

    emailPlaceholder: "Adresa de email",
    namePlaceholder: "Nume",
    passwordPlaceholder: "Parola",
    confirmPasswordPlaceholder: "Confirmă parola",
    termsText: "Am citit și sunt de acord cu Termenii și Condițiile, cu Politicile de Confidentialitate și Utilizare a cookie-urilor și tehnologiilor similare",
    newsletterText: "Vreau să primesc ofertele KRIT.ro pe email. Confirm că am peste 18 ani.",


    loginButton: "Login",
    registerButton: "Înregistrare cont nou",
    shortRegisterButton: "Cont nou",
    forgotPasswordButton: "Am uitat parola",
    backToLoginButton: "Înapoi la login",
    submitRegistrationButton: "Înregistrează-te",

    invalidCredentials: "Email sau parola greșite",
    unknownAuthError: "A apărut o problemă la autentificare",

    emailInvalidError: "Adresa de email este invalidă",
    emailRequiredError: "Adresa de email este necesară",
    emailExistsError: "Adresa de email există deja",
    nameRequiredError: "Numele este necesar",
    passwordRequiredError: "Parola este necesară",
    passwordTooShortError: "Parola trebuie să aibă peste 8 caractere",
    passwordConfirmationMismatchError: "Parolele sunt diferite",
    termsError: "Trebuie să accepți termenii KRIT",

    registerSuccessTitle: "Felicitări, contul tău a fost creat!",
    registerSuccessText: "Ți-am trimis un email cu confirmarea creării contului. În câteva secunde vei fi redirecționat la pagina principală.",
    registerSuccessButton: "Continuare",

    resetSuccessTitle: "Parola a fost schimbată",
    resetSuccessText: "Te poți autentifica acum cu noua ta parolă",
    resetSuccessButton: "Înapoi la login",

    forgotPasswordTitle: 'Recuperare parolă',
    forgotPasswordInfo: 'Introdu adresa de email și te vom ajuta să îți resetezi parola.',
    recoverPasswordButton: 'Recuperează parola',

    resetPasswordTitle: 'Schimbă-ți parola',
    resetPasswordText: 'Ai primit un email cu un cod de 4 caractere. Te rugam sa introduci codul mai jos pentru a îți putea schimba parola.',
    tokenPlaceholder: 'Cod confirmare',
    resetPasswordButton: 'Schimbă parola',

    tokenRequiredError: 'Codul de confirmare este obligatoriu',
    tokenLengthError: 'Codul de confirmare trebuie sa aibă 4 caractere',
    tokenInvalidError: 'Codul de confirmare este invalid',

    saveAddressButton: 'Salvează',
    cancelButton: "Anulează"
}


