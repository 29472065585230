import React from "react"
import dynamic from "next/dynamic"
import theme from "../../../../config/theme"
import EmptyCart from "./EmptyCart"

const PopulatedCart = dynamic(() => import('./PopulatedCart'));

const CartOverlay = (props) => (
    <div className="cart-overlay">
        <style jsx>{`
            .cart-overlay {
                height: 100%;
                background-color: ${theme.colors.popover.background};
                color: ${theme.colors.popover.text};
                border-top: 2px solid ${theme.colors.gradients.redA};
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
                margin-right: 10px;
            }

            .close-button {
                
            }
        `}</style>  
       {(!props.cart.products || props.cart.products.length === 0) && 
       (!props.cart.cards || props.cart.cards.length === 0) ? 
            <EmptyCart fullFeatured={props.fullFeatured}/> : 
            <PopulatedCart onUpdate={props.onUpdate} cart={props.cart} onRemove={props.onRemove} onVoucherRemove={props.onVoucherRemove} fullFeatured={props.fullFeatured}/>
        }
    </div>
)

export default CartOverlay;