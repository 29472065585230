import React from "react"
import { useState } from 'react';

import { CartStrings } from '../../../../config/strings';
import Icon from "react-icons-kit";
import { chevronDown } from "react-icons-kit/feather";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import Popover from "../../../elements/Base/Popover";
import theme from '../../../../config/theme';
import CartOverlay from './CartOverlay';
import CountBubble from './CountBubble';
import Link from 'next/link';
import { shoppingBasket } from "react-icons-kit/fa";

const Cart = () => {

    const [ popoverOpen, setPopoverOpen ] = useState(false);

    let operationTimeout = null;

    const  closePopover = () => {
        setPopoverOpen(false);
    }

    const openPopover = () => {
        setPopoverOpen(true);
    }

    const onMouseOver = () => {
        clearTimeout(operationTimeout);
        operationTimeout = setTimeout(() => {
            openPopover();
        }, 50)
    }

    const onMouseOut = () => {
        clearTimeout(operationTimeout);
        operationTimeout = setTimeout(() => {
            closePopover();
        }, 100)
    }

    return (
        <GlobalContext.Consumer>
            {(GlobalContext) => (<>
                <style jsx>{`
                    .cart-wrapper {
                        height: 100%;
                    }

                    .cart-container {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        position: relative;
                        cursor: pointer;
                        padding: 0 10px;
                        transition: all 0.1s ease-in;
                        margin-right: 10px;
                        box-sizing: border-box;
                    }

                    .cart-container.active {
                        background: linear-gradient(to right,${theme.colors.gradients.redA} 10%,${theme.colors.gradients.redB});
                    }

                    .cart-wrapper {
                        margin-left: 10px;
                        margin-right: 10px;
                        position: relative;
                    }

                    .cart-text {
                        flex-shrink: 0;
                    }

                    .cart-icon {
                        margin-right: 5px;
                        height: 24px;
                        overflow: visible;
                        position: relative;
                    }

                    .cart-wrapper-desktop .cart-text {
                        width: 80px;
                        text-align: center;
                    }
                `}</style>
                
                <div className="cart-wrapper hide-gt-m">
                    <Link href="/cos">
                        <div className={`hide-gt-m cart-container`}>
                            <div className="cart-icon">
                                <Icon size={24} icon={shoppingBasket}/>
                                <CountBubble count={GlobalContext.cart ? GlobalContext.cart.totalProducts : 0}/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="cart-wrapper cart-wrapper-desktop hide-lt-m" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                        <div className={`cart-container ${popoverOpen ? 'active' : ''}`}>
                            <div className="cart-icon">
                                <Icon size={24} icon={shoppingBasket}/>
                                <CountBubble count={GlobalContext.cart ? GlobalContext.cart.totalProducts : 0}/>
                            </div>

                            <div className="cart-text">
                                {GlobalContext.cart.totalPrice && GlobalContext.cart.totalPrice > 0 ? 
                                    GlobalContext.cart.grandTotal.toFixed(2) + " Lei" : CartStrings.headerCartButton}
                            </div>
                
                            <div className="dropdown-icon">
                                <Icon size={18} icon={chevronDown}/>
                            </div>
                        </div>

                        <Popover
                            blocking={false}
                            open={popoverOpen}
                            onOpen={openPopover}
                            onClose={closePopover}
                            top={58}
                            right={0}
                            width="450px">
                                {popoverOpen ? 
                                    <CartOverlay 
                                        onUpdate={GlobalContext.setQuantity}
                                        onRemove={GlobalContext.removeFromCart}
                                        onVoucherRemove={GlobalContext.removeVoucherCode}
                                        cart={GlobalContext.cart || {}}
                                        onLogOut={GlobalContext.logout}/> : null}
                        </Popover>
                       
                </div>
            </>)}
        </GlobalContext.Consumer>
    )
}

export default Cart;