import React from "react"


const rarityMap = {
    'Common': 'common',
    'Uncommon': 'uncommon',
    'Rare': 'rare',
    'Super Rare': 'super-rare',
    'Ultra Rare': 'ultra-rare',
    'Ultimate Rare': 'ultimate-rare',
    'Secret Rare': 'secret-rare',
    'Ghost Rare': 'ghost-rare',
    'Token': 'token',
    'Starlight Rare': 'starlight-rare',
    'Illustration Rare': 'secret-rare',
    'Special Illustration Rare': 'starlight-rare',
}

const rarityNameMap = {
    'Common': 'C',
    'Uncommon': 'U',
    'Rare': 'R',
    'Super Rare': 'SR',
    'Ultra Rare': 'UR',
    'Ultimate Rare': 'UtR',
    'Secret Rare': 'ScR',
    'Illustration Rare': 'Illustration Rare',
    'Special Illustration Rare': 'Sp. Illustration Rare'
}

const CardRarityBadge = (props) => {
    const variant = props.variantName ?
                        props.variantName === 'reverseHolofoil' ? 'Reverse Holo' :
                        props.variantName === 'holofoil' ? 'Holo' : 
                        props.variantName === 'foil' ? 'Foil' : null : null;

    return (
        <>
            <style jsx>{`
                .badge {
                    font-size: 10px;
                    font-weight: bold;
                    background-color: #EAEAEA;
                    color: #444444;
                    border-radius: 4px;

                    margin: 0px 4px;
                    box-shadow: 0px 0px 2px #444444;
                    align-items: center;
                    justify-content:center;
                    display: flex;
                    flex-direction: row;
                    overflow: hidden;
                }

                .badges {
                    justify-content: center;
                    display: flex;
                    margin-top: 10px;
                }

                .rarity-token {
                    background-color: #444444;
                    color: #FFFFFF;
                }

                .rarity-common {
                    background-color: #CCCCCC;
                    color: #444444;
                }

                .rarity-uncommon {
                    background-color: #8AE68A;
                    color: #FFFFFF;
                }

                .rarity-rare {
                    background-color: #6666FF;
                    color: #FFFFFF;
                }

                .rarity-super-rare {
                    background-color: #FFA500;
                    color: #FFFFFF;
                }

                .rarity-ultra-rare {
                    background-color: #FF00FF;
                    color: #FFFFFF;
                }

                .rarity-secret-rare {
                    background-color: #FFD700;
                }

                .rarity-starlight-rare {
                    background: rgb(255,193,5);
                    background: linear-gradient(90deg, rgba(255,193,5,1) 0%, rgba(250,255,0,1) 30%, rgba(74,255,0,1) 100%);
                }

                .rarity-ultimate-rare {
                    background: #800080;
                    color: #FFFFFF;
                }

                .rarity-ghost-rare {

                }

                .rarity-other {
                    background-color: #EAEAEA;
                    color: #444444;
                }

                .rarity {
                    padding: 0px 2px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                .holo {
                    padding: 0px 2px;
                    display: inline;
                    background: linear-gradient(to right, #2B2D42, #8D99AE, #EF233C);
                    color: #ffffff ;
                    text-shadow: 0px 0px 1px #000000;
                    height: 100%;
    display: flex;
    align-items: center;

                }
            `}</style>

            <div className={`badge`} title={`${props.rarity} ${variant ? `(${variant})` : ''}`}>
                {props.small ? '' : 
                    <div className={`rarity rarity-${rarityMap[props.rarity.trim()] || 'other'}`}>
                        {rarityNameMap[props.rarity.trim()] || props.rarity}
                    </div>}
                {variant ?
                <div className="holo">
                    {variant}
                </div> : null}
            </div>
        </>
    )
}

export default CardRarityBadge;
