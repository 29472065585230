import React from "react"
const Image = (props) => {
    const baseSrc = props.src.split(".").slice(0, -1).join(".");

    return (
        <picture>
            <style jsx>{`
                img {
                    height: ${props.height || '100%'};
                    margin-top: ${props.margintop || '0'};
                }
            `}</style>
            {props.format ? null : 
                <source srcSet={`${baseSrc}.webp`} type="image/webp"/>}
            <img {...props}/>
        </picture>
    )
}

export default Image;