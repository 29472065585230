export default {
    orderPageTitle: "Finalizare Comandă",
    orderButton: "Plaseaza Comanda",
    emailPlaceholder: "Adresa Email",
    namePlaceholder: "Nume si Prenume",
    companyNamePlaceholder: "Nume Companie",
    vatNumberPlaceholder: "CUI",
    registryNumberPlaceholder: "Nr. Înregistrare Reg. Com. (ex. J29/1695/2019)",
    phoneNumberPlaceholder: "Număr de telefon",
    isCompanyPlaceholder: "Persoana Juridică?",
    termsText: "Am citit și sunt de acord cu Termenii și Condițiile, cu Politicile de Confidentialitate și Utilizare a cookie-urilor și tehnologiilor similare",
    cityPlaceholder: "Oras",
    countyPlaceholder: "Judet",
    locationPlaceholder: "Adresa (Ex. Strada Buna Vestire, Ploiesti)",
    addressPlaceholder: "Detalii adresa (Ex. Nr. 55, Bl. 1, Sc. A, etc.)",

    pfLabel: "Persoană Fizică",
    pjLabel: "Persoană Juridică",

    emailErrorInvalid: "Te rugăm să introduci un email valid",
    emailErrorRequired: "Adresa de email este obligatorie",
    nameErrorRequired: "Numele este obligatoriu",
    addressErrorRequired: "Detaliile adresei sunt obligatorii",
    phoneErrorRequired: "Numarul de telefon este obligatoriu",
    companyNameErrorRequired: "Numele societatii este obligatoriu",
    vatNumberErrorRequired: "CUI-ul societatii este obligatoriu",
    registryNumberErrorRequired: "Nr. înregistrare reg. com. este obligatoriu",
    termsErrorRequired: "Trebuie să fii de acord cu termenii și condițiile Krit pentru a putea plasa comanda.",

    billingText: "Datele de livrare sunt aceleasi cu datele de facturare",
    paymentMethodRequired: "Te rugăm să alegi modalitatea de plată",

    thanksForOrder: "Îți mulțumim pentru comandă!",
    orderDetailSubtitle: "Vezi mai jos detaliile comenzii",

    cityErrorRequired: "Te rugam sa alegi un oras valid",
    countyErrorRequired: "Te rugam sa alegi un judet valid",
    locationErrorRequired: "Te rugam sa alegi o adresa valida",

    orderMissing: "Comanda nu a fost gasita",
    orderMissingDetails: "Daca ai plasat o comandă și ai ajuns aici, trimite-ne un email la contact@krit.ro și vom rezolva problema în cel mai scurt timp!",
    addressNamePlaceholder: "Nume adresă",
    isBillingText: "Este adresă de facturare",
    isDeliveryText: "Este adresă de livrare"
}
