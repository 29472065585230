import React from "react"
import theme from "../../../config/theme";
import Link from "next/link";

const Button = React.forwardRef((props, ref) => (
    <div className="button-wrapper">
        <style jsx>{`
            .button-wrapper {
                ${props.padding !== false ? `
                    padding-top: 5px;
                    padding-bottom: 5px;
                ` : ''}
            }

            button {
                background-color: ${theme.colors.button.background};
                color: ${theme.colors.button.text};
                font-size: ${theme.font.text};
                box-sizing: border-box;
                border: 0;
                width: 100%;
                padding: 0px ${props.padding === false ? "0px" : "10px"};
                height: ${props.height ? (props.height + "px") : "48px"};
                font-weight: 700;
                border-color: ${theme.colors.button.lightText};
                cursor: pointer;
                transition: 0.1s;
                ${props.shadow === false ? '' : 
                `box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);`}
                border-radius: ${props.squareBorders ? '0px' : '2px'};
            }

            button:focus, button:active {
                outline: none;
            }

            button.link {
                background-color: transparent;
                color: ${theme.colors.button.darkText};
                box-shadow: none;
            }

            button.link:hover {
                text-decoration: underline;
            }

            button.transparent {
                background-color: transparent;
                border: none;
            }

            button.transparent.primary {
                color: ${theme.colors.button.primaryBackground};
                border: none;
                background-color: transparent;
            }

            button.transparent.secondary {
                color: ${theme.colors.button.secondaryBackground};
                border: none;
                background-color: transparent;
            }

            button.transparent.accent {
                color: ${theme.colors.button.accentBackground};
                border: none;
                background-color: transparent;
            }

            button.primary {
                background-color: ${theme.colors.button.primaryBackground};
                color: ${theme.colors.button.onPrimaryBackground};
                border-color: ${theme.colors.button.primaryBackground};
            }

            button.primary:hover {
                background-color: ${theme.colors.button.primaryBackgroundHover};
            }

            button.secondary {
                background-color: ${theme.colors.button.secondaryBackground};
                color: ${theme.colors.button.onSecondaryBackground};
                border-color: ${theme.colors.button.secondaryBackground};
            }

            button.default:hover {
                background-color: ${theme.colors.button.secondaryBackground};
                color: ${theme.colors.button.onSecondaryBackground};
                border-color: ${theme.colors.button.secondaryBackground};
            }

            button.accent {
                background-color: ${theme.colors.button.accentBackground};
                color: ${theme.colors.button.onAccentBackground};
                border-color: ${theme.colors.button.accentBackground};
            }

            button.default.outlined {
                border-style: solid;
                color: ${theme.colors.button.light};
                border-color: ${theme.colors.button.light};
                border-width: 2px;
                background-color: transparent;
            }

            button.accent.outlined, button.outlined:hover {
                color: ${theme.colors.button.accentBackground};
                border-color: ${theme.colors.button.accentBackground};
                border-color: ${theme.colors.button.secondaryBackground};
            }

            button.primary.outlined {
                color: ${theme.colors.button.primaryBackground};
                background-color: transparent;
                border: 2px solid ${theme.colors.button.primaryBackground};
            }

            button.secondary.outlined {
                color: ${theme.colors.button.secondaryBackground};
                background-color: transparent;
                border: 2px solid ${theme.colors.button.secondaryBackground};
            }

            button.accent.outlined {
                color: ${theme.colors.button.accentBackground};
                background-color: transparent;
                border: 2px solid ${theme.colors.button.accentBackground};
            }

            button.outlined.light {
                color: ${theme.colors.button.darkText};
            }

            button.facebook {
                background-color: ${theme.colors.button.facebookButtonBackground};
                color: ${theme.colors.button.facebookButtontext};
            }

            button.disabled.outlined {
                border: 2px solid ${theme.colors.button.background};
                color: ${theme.colors.button.background};
                background-color: transparent;
                cursor: not-allowed;
            }

            button.facebook:hover, button.google:hover {
                opacity: 0.9;
            }

            button.heavy {
                font-weight: 900;
            }

            button.google {
                font-family: 'Roboto';
                font-size: 14px;
                color: ${theme.colors.button.googleButtonText};
                background-color: ${theme.colors.button.googleButtonBackground};
                border: 1px solid ${theme.colors.button.googleButtonBorder};
            }

            button.custom {
                background-color: ${props.backgroundColor};
                color: ${props.textColor};
            }

            button.outlined.custom {
                background-color: transparent;
                border: 2px solid ${props.backgroundColor};
                color: ${props.textColor};
            }
            
            
            
        `}</style>
        <button ref={ref} className={`
            ${
                props.type === "primary" ? "primary" :
                props.type === "secondary" ? "secondary" :
                props.type === "accent" ? "accent" :
                props.type === "disabled" ? "disabled" :
                props.type === "link" ? "link" :
                props.type === "google" ? "google" :
                props.type === "facebook" ? "facebook" :
                props.type === "custom" ? "custom" :
                props.type === "transparent" ? "transparent" :
                "default"
            } 

            ${ props.variant === "outlined" ? "outlined" : 
               props.variant === "transparent" ? "transparent" : ""}

            ${ props.heavy === true ? "heavy" : ""}
        `} 
        type={props.submit ? 'submit' : 'button'}
        onClick={props.onClick}
        disabled={props.disabled}>
            {props.children}
        </button>
    </div>
))

export default React.forwardRef((props, ref) => (
    props.href ? 
        <Link href={props.href} as={props.as || props.href}>
            <a>
                <style>{`
                    a {
                        width: 100%;
                    }
                `}</style>
                <Button {...props} ref={ref}>
                    {props.children}
                </Button>
            </a>
        </Link> :
        <Button {...props} ref={ref}>
            {props.children}   
        </Button>
    
));