import Responsive from './Responsive';
import GlobalCSS from './GlobalCSS';
import HeadSection from './HeadSection';

const Index = () => (
    <>
        <HeadSection/>
        <Responsive/>
        <GlobalCSS/>
    </>
)

export default Index;