import React from "react"

const CardSetBadge = (props) => {
    return (
        <div>
            <style jsx>{`
                .badge {
                    font-size: 10px;
                    max-height: 12px;
                    font-weight: 700;
                    background-color: #EAEAEA;
                    color: #444444;
                    border-radius: 4px;
                    padding: 2px 4px;
                    margin: 0px 4px;
                    text-align: center;
                }
            `}</style>

            <div className="badge" title={`${props.product.cardFilterSet} #${props.product.number} (Lb. Engleza)`}>
                {props.full ? `${props.product.expansionName} ${props.product.number}` : props.product.expansionCode}</div>
        </div>
    )
}

export default CardSetBadge;
