import React from "react";
import theme from "../../../../../config/theme";
import { splitPrice } from "../../../../../utils/misc";
import CardQuantityBadge from "../../../../elements/Card/CardQuantityBadge";
import CardRarityBadge from "../../../../elements/Card/CardRarityBadge";
import CardSetBadge from "../../../../elements/Card/CardSetBadge";
import CardThumbnail from "../../../../elements/Card/ProductThumbnail";
import ProductThumbnail from "../../../../elements/Product/ProductThumbnail";

const SearchOverlayResult = (props) => {
  let price = splitPrice(props.result.totalPrice);
  let oldPrice =
    props.result.totalOldPrice !== props.result.totalPrice
      ? splitPrice(props.result.totalOldPrice)
      : null;

  if (props.type === "card") {
    price = splitPrice(props.result.price);
    oldPrice = null;
  }

  return (
    <div className="search-overlay-result" onClick={props.onClick}>
      <style jsx>{`
        .search-overlay-result {
          display: flex;
          margin: 10px 0;
          padding: 10px;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          transition: all 0.1s ease;
        }

        .search-overlay-result:hover,
        .search-overlay-result:active {
          background-color: ${theme.colors.search.hoverItem};
        }

        .search-overlay-result .result-thumbnail {
          height: 48px;
          width: 48px;
          min-width: 48px;
          overflow: hidden;
          margin-right: 20px;
          position: relative;
        }

        .search-overlay-result .result-thumbnail img {
          height: 100%;
        }

        .search-overlay-result .result-title {
          margin-bottom: 7px;
          font-weight: bold;
        }

        .search-overlay-result .result-price {
          font-weight: bold;
          display: flex;
        }

        .search-overlay-result .promo-price {
          color: ${theme.colors.base.accent};
          font-size: 1.1em;
          font-weight: bold;
          margin-right: 10px;
        }

        .search-overlay-result .old-price {
          color: ${theme.colors.search.text};
          text-decoration: line-through;
          font-size: 0.9em;
        }

        .inline-title {
          display: flex;
          flex-direction: row;
        }

        .card-extra-info {
          display: flex;
        }

        .game-thumbnail {
          margin-left: 10px;
        }
      `}</style>
      <div className="result-thumbnail">
        {props.type === "card" ? (
          <CardThumbnail product={props.result} />
        ) : (
          <ProductThumbnail product={props.result} />
        )}
      </div>
      <div className="result-text">
        <div className="result-title">
          {props.type === "card" ? (
            <div className="inline-title">
              {props.type === "card" ? (
                <div className="game-thumbnail">
                  <img
                    src={`/img/${
                      props.result.game === 3
                        ? "ygo-logo.png"
                        : props.result.game === 1
                        ? "mtg-logo.png"
                        : props.result.game === 18
                        ? "op-logo.png"
                        : props.result.game === 21
                        ? "swu-logo.png"
                        : "pkm-logo.png"
                    }`}
                    alt="Game"
                    height="20"
                  />
                </div>
              ) : null}
              {props.result.name}
            </div>
          ) : (
            props.result.title
          )}
        </div>
        {props.type === "card" ? (
          <div className="card-extra-info">
            <CardRarityBadge
              rarity={props.result.rarity}
              variantName={props.result.variantName}
            />
            <CardQuantityBadge product={props.result} />
            <CardSetBadge product={props.result} />
          </div>
        ) : null}
        <div className="result-price">
          <div className={`price ${oldPrice ? "promo-price" : ""}`}>
            <span className="integer-price">{price[0]}.</span>
            <span className="fractional-price">{price[1]}</span>
            <span className="currency">{process.env.currency}</span>
          </div>
          {oldPrice ? (
            <div className={`price old-price`}>
              <span className="integer-price">{oldPrice[0]}.</span>
              <span className="fractional-price">{oldPrice[1]}</span>
              <span className="currency">{process.env.currency}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchOverlayResult;
