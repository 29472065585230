import React from "react"
import SearchOverlayHeader from "./SearchOverlayHeader";

import { SearchStrings } from '../../../../../config/strings';
import SearchOverlayResult from "./SearchOverlayResult";
import theme from "../../../../../config/theme";
import Image from "../../../../elements/Base/Image";

const SearchOverlayResults = (props) => {
    const productResults = props.results.products;
    const cardResults = props.results.cards;

    if(props.term.length === 0) {
        return null;
    }

    return (
        <div className="search-results-wrapper">
            <style jsx>{`
                .results {
                    height: auto;
                    overflow-y: auto;
                }

                @media only screen and (min-width: ${theme.responsive.m}px) {
                    .results {
                        max-height: 60vh;
                    }
                }

                .yugioh-singles {
                    padding: 10px;
                    height: 32px;

                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            `}</style>
                {(props.mode === 'product') ?
                productResults.length === 0 ? <SearchOverlayHeader shadow={false}>
                    {SearchStrings.missingResults}
                </SearchOverlayHeader> :
                <SearchOverlayHeader>
                    {SearchStrings.searchResults}
                </SearchOverlayHeader> : null}

                { ( props.mode === 'card') ?
                    <SearchOverlayHeader shadow={cardResults.length > 0}>
                        <div>{cardResults.length > 0 ? `Rezultate rapide` : `Nu au fost gasite carti TCG dupa termenul cautat`}</div>
                    </SearchOverlayHeader>
                : null}

            <div className="results">
                {props.mode === "product" ? productResults.map((result, index) => (
                    <SearchOverlayResult
                        onClick={() => props.onProductClick(result.slug)}
                        key={`result-${index}`}
                        result={result}/>
                )) : props.mode === "card" ? cardResults.map((result, index) => (
                    <SearchOverlayResult
                        type='card'
                        onClick={() => props.onCardClick(result.id)}
                        key={`result-${index}`}
                        result={result}/>
                )) : null}
            </div>
        </div>
    )
}

export default SearchOverlayResults;
