import React from "react";
import theme from "../../../config/theme";

const InfoText = (props) => {
  return (
    <div className="info-text">
      <style jsx>{`
        .info-text {
          width: 100%;
          background-color: #b9d867;
          background: linear-gradient(
            to right,
            #3ead9c 10%,
            ${theme.colors.gradients.blueB}
          );
          //background: linear-gradient(to right,${theme.colors.gradients
            .orangeA} 10%,${theme.colors.gradients.orangeA});
          //border-top: 2px solid ${theme.colors.gradients.redA};
          color: ${theme.colors.base.white};
          display: flex;
          font-size: 14px;
          text-transform: uppercase;
          justify-content: center;
          align-items: center;

          box-sizing: border-box;
          height: 32px;

          z-index: 9000;
        }

        .tiny {
          font-size: 14px;
        }

        .info-text-text {
          margin-left: 5px;
        }

        @media only screen and (max-width: ${theme.responsive.s}px) {
          .info-text {
            font-size: 11px;
          }
        }
      `}</style>
      <img src="/img/free-delivery.png" height="28px" />
      <div className="info-text-text">
        Livrare <strong>gratuita</strong> la comenzi peste 190 Lei
        {/* <strong>Black Friday</strong> <span className="tiny">11 - 13 Noiembrie</span> */}
      </div>
    </div>
  );
};

export default InfoText;
