import React from "react"
import Button from "../../../../elements/Base/Button";
import { SearchStrings } from '../../../../../config/strings';
import ButtonV2 from "../../../../elements/Base/ButtonV2";
import theme from "../../../../../config/theme";
import SearchTypeSelector from "../SearchTypeSelector";

const SearchOverlayMoreButton = (props) => (
    <div className='search-overlay-more'>
        <style jsx>{`
            .search-overlay-more {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-bottom: 5px;
                padding-top: 5px;
                box-shadow: ${(props.results.products.length > 0 && props.mode === 'product') ||
                    (props.results.cards.length > 0 && props.mode === 'card') ?
                    '0px -2px 5px 0px #DDDDDD': ''};
            }

            .search-overlay-more > div.products {
                flex-grow: 1;
                padding: 0 10px;
            }
        `}</style>

        {/* <div className="hide-gt-m">
            <SearchTypeSelector mode={props.mode} onChangeMode={props.onChangeMode} view="mobile"/>
        </div> */}
        {props.results.products.length > 0 && props.mode === 'product' ?
            <div className="products">
                <ButtonV2 type="blue" variant='' onClick={props.onSearch} padding={false}>
                    Cautare
                </ButtonV2>
            </div> : props.results.cards.length > 0 && props.mode === 'card' ?
            <div className="products">
                <ButtonV2 type="dark-reverse" variant='' onClick={props.onSingleSearch} padding={false}>
                    Cautare
                </ButtonV2>
            </div>
        : null}



    </div>
)

export default SearchOverlayMoreButton;
