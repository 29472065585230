import React from 'react';
import nookies from 'nookies';

import { handleError } from '../utils/errorHandler';
import Api from '../utils/api';
import kanalytics from '../utils/kanalytics';

export const GlobalContext = React.createContext();

export const getUser = async (ctx = null) => {
    const sessionCookie = nookies.get(ctx).session

    if(!sessionCookie) {
        return false;
    }

    let response = null;

    try {
        response = await Api.get('/account', null, null, ctx);
    } catch (e) {
        handleError(e);
    }

    if(!response || !response.user) {
        return false;
    }

    return response.user
}

export const getCart = async (ctx = null) => {
    return Api.get('/public/cart', null, null, ctx);
}

export const getSessionData = async (ctx = null) => {
    // const sessionCookie = nookies.get(ctx).session

    let response = null;

    try {
        response = await Api.get('/public/session-data', null, null, ctx);
    } catch (e) {
        handleError(e);
    }

    if(!response || typeof response.user === 'undefined' || typeof response.cart === 'undefined') {
        return false;
    }



    // if(!sessionCookie) {
    //     kanalytics.logEvent('created-session')
    // }
    nookies.set(ctx, "session", response.token, {
        path: "/",
        maxAge: 3600 * 24 * 30
        //maxAge: process.env.shortAuthLifespan
    });


    return response
}