import nookies from 'nookies';
import TagManager from 'react-gtm-module';


export const events = {
    ADD_TO_CART: 'add_to_cart',
    REMOVE_FROM_CART: 'remove_from_cart',
    CHECKOUT: 'begin_checkout',
    PURCHASE: 'purchase',
}

const tagManagerArgs = {
    gtmId: process.env.googleAnalyticsId
}

function gtag() {
    (window.dataLayer || []).push(arguments);
}


export const initGA = () => {
    if(process.env.NODE_ENV === 'development') {
        console.log('GTM init', process.env.googleAnalyticsId)
    }

    TagManager.initialize(tagManagerArgs);

    const consent =  nookies.get('consent')

    if(consent && consent.kritCookieConsent) {
        updateConsent(true, true)
    } else {
        updateConsent(false, true)
    }
}

export const updateConsent = (consent, type = false) => {
    const consentMode = {
      functionality_storage: consent ? 'granted' : 'denied',
      security_storage: consent ? 'granted' : 'denied',
      ad_storage: consent ? 'granted' : 'denied',
      analytics_storage: consent ? 'granted' : 'denied',
      personalization: consent ? 'granted' : 'denied',
      ad_user_data: consent ? 'granted' : 'denied',
      ad_personalization: consent ? 'granted' : 'denied',
    };

    gtag('consent', type ? 'default' : 'update' , consentMode);
}


export const logEvent = (event, data) => {
    if(process.env.NODE_ENV === 'development') {
        console.log(`Logging event`, event, data);
    }

    window.dataLayer.push({
        event,
        data
    })
}

//Ecommerce events
export const viewItemListEvt = (data) => {

}

export const selectItemEvt = (data) => {

}

export const viewItemEvt = (data) => {

}

export const addToCartEvt = (data) => {
    const eventData = {
        event: events.ADD_TO_CART,
        ecommerce: {
            currency: "RON",
            value: data.price,
            affiliation: "Online Store",
            coupon: "",
            items: [{
                item_id: data.id,
                item_name: data.title,
                discount: data.discount,
                price: data.price,
                quantity: 1
            }]
        }
    }

    if(process.env.NODE_ENV === 'development') {
        console.log(`Logging addToCart event`, eventData, data);
    }

    (window.dataLayer || []).push(eventData);
}

export const removeFromCartEvt = (data, quantity = null) => {
    const eventData = {
        event: events.REMOVE_FROM_CART,
        ecommerce: {
            currency: "RON",
            value: data.price * (quantity || data.quantity),
            items: [{
                item_id: data.id,
                item_name: data.title,
                discount: data.discount,
                price: data.totalPrice,
                quantity: quantity || data.quantity
            }]
        }
    }

    if(process.env.NODE_ENV === 'development') {
        console.log(`Logging removeFromCart event`, eventData, data);
    }

    (window.dataLayer || []).push(eventData);
}

export const viewPromotionEvt = (data) => {

}

export const selectPromotionEvt = (data) => {

}

export const beginCheckoutEvt = (data) => {
    const eventData = {
        event: events.CHECKOUT,
        ecommerce: {
            affiliation: 'Online Store',
            currency: 'RON',
            value: data.grandTotal,
            tax: 0,
            shipping: data.shippingCost,
            coupon: '',
            items: data.products.map(product => ({
                item_name: product.title,
                item_id: product.id,
                price: product.totalPrice,
                quantity: product.quantity
            }))
        }
    }


    if(process.env.NODE_ENV === 'development') {
        console.log(`Logging checkout event`, eventData, data);
    }

    (window.dataLayer || []).push(eventData);
}

export const purchaseEvt = (data) => {

    const eventData = {
        event: events.PURCHASE,
        ecommerce: {
            transactionId: data.orderNumber,
            affiliation: 'Online Store',
            currency: 'RON',
            value: data.grandTotal,
            tax: 0,
            shipping: data.shippingCost,
            coupon: '',
            items: data.products.map(product => ({
                item_name: product.product.title,
                item_id: product.product.id,
                price: product.unitPrice,
                quantity: product.quantity
            }))
        }
    }

    if(process.env.NODE_ENV === 'development') {
        console.log(`Logging checkout event`, eventData, data);
    }

    // (window.dataLayer || []).push({ ecommerce: null });
    (window.dataLayer || []).push(eventData);
}

export const refundEvt = (data) => {

}
