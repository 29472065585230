import React from "react"
import theme from "../../../../../config/theme";

const SearchOverlayHeader = (props) => (
    <div className="search-overlay-header">
        <style jsx>{`
             .search-overlay-header {
                padding-left: 10px;
                padding-bottom: 5px;
                padding-top: 5px;
                color: ${theme.colors.base.baseDark};
                text-transform: uppercase;
                font-size: ${theme.font.subtext};
                font-weight: 700;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                box-shadow: ${props.shadow ? '0px 2px 5px 0px #DDDDDD' : ''};
            }
        `}</style>
        {props.children}
    </div>
)

export default SearchOverlayHeader;