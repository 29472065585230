import Api from './api.js'

const logEvent = async (event, data) => {
    // try {
    //     await Api.post("/evt", {}, {}, {
    //         event,
    //         ...data
    //     });
    // } catch (e) {
    //     //handle analytics fail
    // }
    
}

export default {
    logEvent
}