import React from "react"
import TopFooter from "./TopFooter"
import MiddleFooter from "./MiddleFooter"
import BottomFooter from "./BottomFooter"
import Head from "next/head"

const Footer =  () => {
    return (
        <div className="footer-wrapper">
            <style jsx>{`
                .footer-wrapper {
                    margin-top: 50px;
                }
            `}</style>
            <TopFooter/>
            <MiddleFooter/>
            <BottomFooter/>
        </div>
    )
}

export default Footer;