import React from "react"
import { useState, useEffect} from "react";
import Router from 'next/router';
import Blocker from "./Blocker"
import onClickOutside from "react-onclickoutside"
import theme from "../../../config/theme";

const PopOver = (props) => {
    const closePopover = () => {
        if(props.onClose) {
            props.onClose();
        }
    }

    useEffect(() => {
        Router.events.on('routeChangeComplete', closePopover);
    
        return () => {
            Router.events.off('routeChangeComplete', closePopover);
        }
    })

    useEffect(() => {
        // if(props.open === true) {
        //     document.body.style.overflow = 'hidden';
        // } else {
        //     document.body.style.overflow = 'unset';
        // }
    }, [props.open]);

    PopOver.handleClickOutside = () => closePopover;

    return (
        <>
            <div className={`popover-container ${props.open ? 'open' : ''}`}>
                <style jsx>{`
                    .popover-container {
                        top: ${props.top !== undefined ? props.top + 'px' : 'auto'};
                        left: ${props.left !== undefined ? props.left + 'px' : 'auto'};
                        right: ${props.right !== undefined ? props.right + 'px' : 'auto'};
                        position: absolute;
                        width: ${props.width !== undefined ? props.width : '100%'};
                        z-index: 8000;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.1s ease-in;
                    }

                    .popover-container.open {
                        visibility: visible;
                        opacity: 1;
                    }

                    .popover-contents {
                        width: 100%;
                        height: auto;
                        transition: all 0.1s ease;
                    }

                    @media only screen and (max-width: ${theme.responsive.m}px) {
                        .popover-container {
                            position: fixed;
                            left: 0;
                            width: 100%;
                            height: calc(100% - ${props.top|| 0}px);
                        }

                        .popover-contents {
                            height: 100%;
                        }
                    }
                `}</style>
                {props.blocking === true ? 
                    <Blocker show={true} onClick={closePopover}/> :
                    null }

                <div className="popover-contents">
                    {props.children}
                </div>
            </div>
        </>
    )
}

PopOver.prototype = {};

const clickOutsideConfig = {
    handleClickOutside: () => PopOver.handleClickOutside
  };
  

export default onClickOutside(PopOver, clickOutsideConfig);