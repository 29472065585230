import React, { useState } from "react"
import Button from "../../../../elements/Base/Button";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/feather";
import { SearchStrings } from '../../../../../config/strings';
import SearchOverlayHints from "./SearchOverlayHints";
import SearchOverlayResults from "./SearchOverlayResults";
import theme from "../../../../../config/theme";
import SearchOverlayMoreButton from "./SearchOverlayMoreButton";

const SearchOverlay = (props) => {
    return <div className="search-overlay-wrapper">
        <style jsx>{`
            .search-overlay-wrapper {
                width: 100%;
                box-sizing: border-box;
                padding-top: 50px;
                color: ${theme.colors.search.text};
                background-color: ${theme.colors.search.background};
                box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
            }

            .search-overlay {
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .search-overlay-content {
                flex-grow: 1;
                overflow-y: hidden;
            }

            .back {
                justify-self: flex-end;
                margin-top: 10px;
            }


            @media only screen and (max-width: ${theme.responsive.m}px) {
                .search-overlay {
                    height: 100%;
                    overflow-y: auto;
                }

                .search-overlay-content {
                    overflow-y: scroll;
                }

                .search-overlay-wrapper {
                    height: 100%;
                    border-radius: 0px;
                }
            }
        `}</style>

        <div className="search-overlay" aria-hidden="true">
            {props.display === "peek" ?
            <div className="back">
                    <SearchOverlayMoreButton mode={props.mode} results={props.results} onChangeMode={props.onChangeMode} onSearch={props.onSearch} onSingleSearch={props.onSingleSearch}/>
            </div> : null}
            <div className="search-overlay-content">
                {props.display === "peek" ?
                    <SearchOverlayResults
                        term={props.term}
                        mode={props.mode}
                        results={props.results}
                        onSearch={props.onSearch}
                        onProductClick={props.onProductClick}
                        onCardClick={props.onCardClick}/> :
                    <SearchOverlayHints
                        hints={props.hints}
                        onSearch={props.onSearch}/>
                }
            </div>
        </div>
    </div>
}

export default SearchOverlay;
