import React from "react"
import { useState, useRef, useEffect } from 'react';
import Icon from 'react-icons-kit';
import { search } from 'react-icons-kit/feather';
import Popover from '../../../elements/Base/Popover';
import SearchOverlay from './SearchOverlay';
import { x } from 'react-icons-kit/feather';
import theme from '../../../../config/theme';

import Router from 'next/router';
import { friendlySearchValue } from '../../../../utils/misc';

import Api from '../../../../utils/api';
import { SearchStrings } from '../../../../config/strings';
import SearchTypeSelector from "./SearchTypeSelector";

const Search = (props) => {
    const expanded = props.expanded;

    const [ focused, setFocused ] = useState(false);
    const [ searchValue, setSearchValue ] = useState("");
    const [ peekResults, setPeekResults ] = useState({ products: [], cards: [] });
    const [ hintResults, setHintResults ] = useState([]);
    const [ resultDisplay, setResultDisplay ] = useState("hint");
    const [ mode, setMode ] = useState(props.mode && props.mode === 'card'? 'card' : 'product');

    const searchRef = useRef(null);
    let changeTimeout = null;

    useEffect(() => {
        if(focused === true) {
            searchRef.current.focus();
        } else {
            searchRef.current.blur();
        }

        if(searchValue.length > 1) {
            clearTimeout(changeTimeout);

            changeTimeout = setTimeout(() => {
                fetchPeek();
            }, 200)

        } else {
            setResultDisplay("hint");
        }
    }, [focused, searchValue])

    useEffect(() => {
        setMode(props.mode);
    }, [props.mode])

    const focusSearch = () => {
        if(hintResults.length === 0) {
            fetchHints();
        }

        setFocused(true);
        document.body.style.overflow = 'hidden'
    }

    const unfocusSearch = () => {
        setSearchValue("");
        setFocused(false);
        document.body.style.overflow = 'unset'
    }

    const handleChange = (e) => {
        setSearchValue(e.target.value);
    }

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') {
            mode === 'product' ? performSearch() : performSingleSearch();
        } else if(e.key === 'Escape'){
            unfocusSearch();
        }
    }

    const performSearch = (value = "") => {
        const realSearchValue = (value.length === 0 || typeof value !== "string") ? searchValue : value;

        if(!focused || (realSearchValue.length < 1)) {
            focusSearch();
        } else {
            const finalSearchValue = friendlySearchValue(realSearchValue);

            unfocusSearch();
            Router.push('/cautare/[query]', `/cautare/${finalSearchValue}`).then(() => window.scrollTo(0, 0));
        }
    }

    const performSingleSearch = (value = "") => {
        const realSearchValue = (value.length === 0 || typeof value !== "string") ? searchValue : value;

        if(!focused || (realSearchValue.length < 1)) {
            focusSearch();
        } else {
            const finalSearchValue = friendlySearchValue(realSearchValue);

            unfocusSearch();
            Router.push('/cautare-singles/[query]', `/cautare-singles/${finalSearchValue}`).then(() => window.scrollTo(0, 0));
        }
    }

    const jumpToProduct = (slug) => {
        unfocusSearch();
        Router.push('/produs/[slug]', `/produs/${slug}`).then(() => window.scrollTo(0, 0))
    }

    const jumpToCard = (id) => {
        unfocusSearch();
        Router.push('/single/[id]', `/single/${id}`).then(() => window.scrollTo(0, 0))
    }

    const fetchPeek = async () => {
        const peek = await Api.get('/public/search/peek/' + encodeURIComponent(searchValue));

        if(peek.success) {
            setPeekResults({
                products: peek.data.products || [],
                cards: peek.data.cards || []
            });
        } else {
            setPeekResults({
                products: [],
                cards: []
            });
        }

        if(resultDisplay !== "peek") {
            setResultDisplay("peek");
        }
    }

    const fetchHints = async () => {
        const hints = await Api.get('/public/search/hints');

        if(hints.success && hints.data.searches && hints.data.searches.length > 0) {
            setHintResults(hints.data.searches.map(hint => hint.term));
        } else {
            setHintResults([]);
        }
    }



    return (
        <div className="search-wrapper">
            <style jsx>{`
                .search-wrapper {
                    flex-grow: 1;
                }

                .search {
                    height: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex-grow: 1;
                    z-index: 10000;

                }

                .search-icon {
                    padding-left: 15px;
                    padding-right: 15px;
                    color: ${theme.colors.search.lightText};
                    z-index: inherit;
                    height: 40px;
                    align-items: center;
                    display: flex;
                    cursor: pointer;
                }

                .search-field-wrapper .search-icon {
                    color: ${theme.colors.search.text};
                }

                .search-field-container .search-icon {
                    background-color: ${theme.colors.search.background};
                    display: flex;
                    align-items: center;
                }

                .close-icon {
                    background-color: ${theme.colors.search.background};
                    color: ${theme.colors.search.text};
                    padding: 0px 10px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    z-index: inherit;
                    cursor: pointer;
                }

                .search-field {
                    height: 40px;
                    width: 100%;
                    border: 0;
                    padding: 0;
                    text-indent: 10px;
                    font-size: ${theme.font.subtitle};
                    z-index: inherit;
                }

                .search-field:focus, .search-field:active {
                    outline: none;
                }

                .search-field-container {
                    height: 100%;
                    width: 100%;
                    position: relative;
                    z-index: 9000;
                }

                .search-field-wrapper {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 9100;
                    overflow: hidden;
                    border-radius: 10px;
                }

                @media only screen and (max-width: ${theme.responsive.m}px) {
                    .search-field-container {
                        position: fixed;
                        top: 0;
                        left: 0;
                        height: 50px;
                        z-index: 9000;
                    }
                }
            `}</style>

            <div className="search">
                {focused ? null :
                    <div className={`
                        ${expanded ? 'hide-gt-m' : ''}
                        search-icon button
                    `}
                    onClick={focusSearch}>
                        <Icon size={24} icon={search}/>
                    </div>}

                <div className={`${focused ? 'active' : (expanded ? 'hide-lt-m' : 'hide')} search-field-container`}>
                    <div className="search-field-wrapper">
                        <input
                            placeholder={SearchStrings.searchPlaceholder}
                            type="text"
                            className="search-field"
                            onFocus={focusSearch}
                            ref={searchRef}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            value={searchValue}/>

                        {focused ? <>
                            <div>
                                <SearchTypeSelector mode={mode} onChangeMode={setMode} view="desktop"/>
                            </div>
                            <div className="button close-icon" onClick={unfocusSearch}>
                                <Icon size={24} icon={x}/>
                            </div>
                        </>
                             : null
                        }

                        <div className="button search-icon" onClick={mode === 'card' ? performSingleSearch : performSearch}>
                            <Icon size={24} icon={search}/>
                        </div>
                    </div>

                    <Popover blocking={true} open={focused} onClose={unfocusSearch} top={0}>
                        {focused ?
                            <SearchOverlay
                                display={resultDisplay}
                                results = {peekResults}
                                hints = {hintResults}
                                mode={mode}
                                term={searchValue}

                                onChangeMode={setMode}
                                onProductClick={jumpToProduct}
                                onCardClick={jumpToCard}
                                onSearch={performSearch}
                                onSingleSearch={performSingleSearch}
                                onClose={unfocusSearch}
                            /> : null}
                    </Popover>
                </div>
            </div>
        </div>
    )
}

export default Search;
