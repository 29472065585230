import React from "react"
import SearchOverlayHeader from "./SearchOverlayHeader";
import { SearchStrings } from '../../../../../config/strings';
import SearchOverlayHint from "./SearchOverlayHint";

const SearchOverlayHints = (props) => {
    const hints = props.hints.slice(0, 6);
    return (
        <div className="search-hints-wrapper">
            {hints.length > 0 ? <SearchOverlayHeader>
                {SearchStrings.frequentSearches}
            </SearchOverlayHeader> :
            <SearchOverlayHeader>
                {SearchStrings.missingHints}
            </SearchOverlayHeader>}
            
            {hints.filter(hint => { 
                return hint.trim();
            }).map((hint, index) => (
                <SearchOverlayHint key={`hint-${index}`} onSearch={props.onSearch}>
                    {hint}
                </SearchOverlayHint>
            ))}
        </div>
    )
}

export default SearchOverlayHints;