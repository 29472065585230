import React from "react"
import { useState } from 'react';

import { AccountStrings } from '../../../../config/strings';
import Icon from "react-icons-kit";
import { user, chevronDown } from "react-icons-kit/feather";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import Popover from "../../../elements/Base/Popover";
import AccountOverlay from './AccountOverlay';
import theme from '../../../../config/theme';

const Account = () => {

    const [ popoverOpen, setPopoverOpen ] = useState(false);

    let operationTimeout = null;

    const  closePopover = () => {
        setPopoverOpen(false);
    }

    const openPopover = () => {
        setPopoverOpen(true);
    }

    const onMouseOver = () => {
        clearTimeout(operationTimeout);
        operationTimeout = setTimeout(() => {
            openPopover();
        }, 50)
    }

    const onMouseOut = () => {
        clearTimeout(operationTimeout);
        operationTimeout = setTimeout(() => {
            closePopover();
        }, 50)
    }

    return (
        <GlobalContext.Consumer>
            {(GlobalContext) => (
                <div className="account-wrapper hide-lt-m" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                    <style jsx>{`
                        .account-wrapper {
                            height: 100%;
                        }

                        .account-container {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            position: relative;
                            cursor: pointer;
                            padding: 0 10px;
                            transition: all 0.1s ease-in;
                        }

                        .account-container.active {
                            background: linear-gradient(to right,${theme.colors.gradients.blueA} 10%,${theme.colors.gradients.blueB});                        }

                        .account-wrapper {
                            margin-left: 10px;
                            margin-right: 10px;
                            position: relative;
                        }

                        .account-text {
                            flex-shrink: 0;
                        }

                        .account-icon, .account-image {
                            margin-right: 5px;
                            height: 24px;
                            overflow: hidden;
                        }

                        .account-image {
                            border-radius: 24px;
                        }

                        .account-image img {
                            height: 100%;
                        }
                        
                    `}</style>
                        <div className={`account-container ${popoverOpen ? 'active' : ''}`}>
                            <div className="account-icon">
                                {GlobalContext.user && GlobalContext.user.image ? 
                                    <div className="account-image">
                                        <img src={GlobalContext.user.image}/>
                                    </div> :
                                    <Icon size={24} icon={user}/>}
                            </div>
                            <div className="account-text">
                                {(GlobalContext.authenticated && GlobalContext.user) ? 
                                    GlobalContext.user.name : AccountStrings.headerAccountButton}
                            </div>
                
                            <div className="dropdown-icon">
                                <Icon size={18} icon={chevronDown}/>
                            </div>
                        </div>

                        <Popover
                            blocking={false}
                            open={popoverOpen}
                            onOpen={openPopover}
                            oncLose={closePopover}
                            top={58}
                            right={0}
                            width="auto">
                                {popoverOpen ? 
                                    <AccountOverlay 
                                        authenticated={GlobalContext.authenticated}
                                        user={GlobalContext.user || {}}
                                        onLogOut={GlobalContext.logout}/> : null}
                        </Popover>
                       
                </div>
            )}
        </GlobalContext.Consumer>
    )
}

export default Account;