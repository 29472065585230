import React from "react"
import theme from "../../../config/theme";

const Responsive = () => (
    <style jsx global>{`
        @media only screen and (max-width: ${theme.responsive.s}px) {
            .hide-lt-s {
                display: none!important;
            }
        }

        @media only screen and (max-width: ${theme.responsive.m}px) {
            .hide-lt-m {
                display: none!important;
            }
        }

        @media only screen and (max-width: ${theme.responsive.l}px) {
            .hide-lt-l {
                display: none!important;
            }
        }

        @media only screen and (min-width: ${theme.responsive.s + 1}px) {
            .hide-gt-s {
                display: none!important;
            }
        }

        @media only screen and (min-width: ${theme.responsive.m + 1}px) {
            .hide-gt-m {
                display: none!important;
            }
        }

        @media only screen and (min-width: ${theme.responsive.l + 1}px) {
            .hide-gt-l {
                display: none!important;
            }
        }
    `}</style>
)

export default Responsive;