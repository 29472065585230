const baseFontSize = "13px";

const titleFontSize = "1.4em";
const subtitleFontSize = "1.1em";

const jumboFontSize = "3em";

const textFontSize = baseFontSize;
const subtextFontSize = "0.9em";

export default {
    base: baseFontSize,
    jumbo: jumboFontSize,
    title: titleFontSize,
    subtitle: subtitleFontSize,
    text: textFontSize,
    subtext: subtextFontSize
}