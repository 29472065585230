import React from "react"
import Image from "next/image";

const ProductThumbnail = (props) => {
   return <Image 
            alt={props.product.title}
            src={`${(props.product || {}).thumbnailSyncedToContent ? 
                        process.env.cdnImageStorage : process.env.imageStorage}/thumb_${(props.product || {}).thumbnail}.jpg`} 
                lazyBoundary="200px"
                unoptimized={true}
                priority={!!props.priority}
                loading={!!props.priority ? 'eager' : 'lazy'}
                placeholder="empty"
                layout="fill"
                objectFit="contain"/>
}

export default ProductThumbnail;