import React from "react"
import Link from 'next/link';

const KLink = (props) => (
    props.external ? 
        <a {...props} target="_blank" rel={props.external ? "noreferrer" : ""}>
            {props.children}
        </a>
    :
        <Link {...props}>
            <a className={props.className || ''}>
                {props.children}
            </a>
        </Link>
)

export default KLink;