/**
 * src/utils/api.js
 * HTTP Request wrapper
 * Wraps around the AXIOS library
 */
import nookies from 'nookies';
import axios from "axios";

const Wrapper = {
  _getUri(endpoint, query) {

    let uri = "";

    uri = process.env.api + endpoint;
    let keys = Object.keys(query);
    let values = Object.values(query);

    for (let i = 0; i < keys.length; i++) {
      uri = uri.replace("[" + keys[i] + "]", values[i]);
    }

    return uri;
  },

  _getHeaders(ctx = null) {
    let headers = {
      "Content-Type": "application/json"
    };

    const sessionCookie = nookies.get(ctx, 'session').session;

    if(sessionCookie) {
      headers.session = sessionCookie;
    }

    let defaultFRef = 'not-set';

    if(typeof document === 'undefined' && ctx) {

      if(ctx && ctx.query) {
        if(ctx.query.gclid) {
          defaultFRef = 'google-ads';
        } else if(ctx.query.fbclid) {
          defaultFRef = 'facebook';
        } else if(ctx.query.kfref) {
          defaultFRef = ctx.query.kfref;
        } else if(ctx.query.tjaid) {
          defaultFRef = 'traffic-junky';
        }
      }
      headers.forwardref = ctx.req.headers.referer || defaultFRef;
      headers.landing = ctx.req.url || 'not-set-server';
    } else if(typeof document !== 'undefined') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if(params.gclid) {
        defaultFRef = 'google-ads';
      } else if(params.fbclid) {
        defaultFRef = 'facebook';
      } else if(params.kfref) {
        defaultFRef = ctx.query.kfref;
      } else if(params.tjaid) {
        defaultFRef = 'traffic-junky';
      }

      headers.forwardref = document.referrer || defaultFRef;
      headers.landing = location.href || 'not-set-client';
    }

    return headers;
  },

  get(endpoint, query, params, ctx = null) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders(ctx);

    return new Promise((resolve, reject) => {
      axios
        .get(uri, {
          params,
          headers,
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },

  post(endpoint, query, params, data, ctx = null) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders(ctx);

    return new Promise((resolve, reject) => {
      axios
        .post(uri, data, {
          params,
          headers,
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },

   patch(endpoint, query, params, data, ctx = null) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders(ctx);

    return new Promise((resolve, reject) => {
      axios
        .patch(uri, data, {
          params,
          headers,
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },

  put(endpoint, query, params, data) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders();

    return new Promise((resolve, reject) => {
      axios
        .put(uri, data, {
          params,
          headers,
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },

  delete(endpoint, query, params) {
    query = query || {};
    params = params || {};

    let uri = this._getUri(endpoint, query);
    let headers = this._getHeaders();

    return new Promise((resolve, reject) => {
      axios
        .delete(uri, {
          params,
          headers,
        })
        .then(response => {
          if (response.data.error) {
            return reject(response.data.error);
          }

          return resolve(response.data);
        })
        .catch(error => {
          if(!error.response) {
            return reject(error);
          }

          return reject(error.response.data.error);
        });
    });
  },
};

export default Wrapper;
