import React from "react"

const Blocker = (props) => (
    <div className={`blocker ${props.show ? 'show' : ''}`} onClick={props.onClick}>
        <style jsx>{`
            .blocker {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                transition: opacity 0.1s ease;
                background-color: black;
                opacity: 0;
                z-index: -1;
        
            }

            .blocker.show {
                opacity: 0.3;
            }
        `}</style>
    </div> 
)

export default Blocker;