import React from "react"
import { useState, useEffect } from 'react';
import theme from "../../../../config/theme"

const CountBubble = (props) => {
    const [ cartUpdate, setCartUpdate ] = useState(false);

    useEffect(() => {
        onCartUpdate();
    }, [props.count])

    
    const onCartUpdate = () => {
        setCartUpdate(true);

        setTimeout(() => {
            setCartUpdate(false);
        }, 150);
    }
    return (
        <div className={`count-bubble ${cartUpdate ? 'update' : ''} ${props.count > 0 ? 'show' : ''}`} >
            <style jsx>{`
                .count-bubble {
                    font-size: 12px;
                    font-weight: 700;
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    background-color: ${theme.colors.gradients.blueA};
                    border-radius: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
                    overflow: hidden;
                    transition: all 0.1s ease-out;
                    visibility: hidden;
                    width: 0;
                    height: 0;
                }

                .count-bubble.show {
                    visibility: visible;
                    height: 22px;
                    width: 22px;
                    top: -7px;
                    left: -7px;
                }

                .count-bubble.show.update {
                    height: 28px;
                    width: 28px;
                    top: -10px;
                    left: -10px;
                    filter: saturate(4);
                }
            `}</style>
            {props.count}
        </div>
    )
}

export default CountBubble;