import React from "react"
import Link from "./Base/Link";
import Image from "./Base/Image";
import theme from "../../config/theme";

const Logo = (props) => (
    <div className="logo-wrapper">
        <Link href="/">
            <style jsx>{`
                .logo-wrapper {
                    height: 100%;
                    margin-right: 10px;

                    display: flex;
                    align-items: center;
                }

                .logo-image {
                    ${props.width ? `
                        width: ${props.width}px;
                    ` : ''}
                    height: 64px;
                    margin-right: 5px;
                    margin-left: 5px;
                }

                .logo-image-mobile {
                    display: none;
                }

                @media only screen and (max-width: ${theme.responsive.s}px) {

                    .logo-image {
                        display: none;
                    }

                    .logo-image-mobile {
                        height: 38px;
                        display: block;
                    }
                }


            `}</style>
            <div className="logo-image">
                <Image src="/img/krit-logo.png"  margintop="7px" alt="Krit.ro" format="png"/>
            </div>

            <div className="logo-image-mobile">
                <Image src="/img/krit-logo-mobile.png" alt="Krit.ro" format="png"/>
            </div>
        </Link>
    </div>
)

export default Logo;