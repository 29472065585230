import React from "react"
import theme from "../../../config/theme";
import Link from "next/link";

const Button = React.forwardRef((props, ref) => (
    <div className="button-wrapper">
        <style jsx>{`
            .button-wrapper {
                ${props.padding !== false ? `
                    padding-top: 5px;
                    padding-bottom: 5px;
                ` : ''}
            }

            button {
                background-color: ${theme.colors.base.lightGray};
                color: ${theme.colors.base.base};
                font-size: ${theme.font.text};
                box-sizing: border-box;
                border: 0;
                width: 100%;
                padding: 0px ${props.padding === false ? "5px" : "10px"};
                height: ${props.height ? (props.height + "px") : "42px"};
                font-weight: 600;

                
                ${props.disabled === true ?
                `cursor: not-allowed;` : `cursor: pointer;`}
                
                transition: 0.1s;
                ${props.rounded !== false ? `border-radius: 4px;` : ``}
                overflow: hidden;
            }

            button.dark {
                background: linear-gradient(to right,${theme.colors.gradients.darkA} 10%,${theme.colors.gradients.darkB});
                color: ${theme.colors.base.white};
                border-color: ${theme.colors.gradients.darkA};

            }

            button.dark-reverse {
                background: linear-gradient(to left,${theme.colors.gradients.darkReverseA} 10%,${theme.colors.gradients.darkReverseB});
                color: ${theme.colors.base.white};
                border-color: ${theme.colors.gradients.darkA};
            }

            button.outline.text-dark {
                color: ${theme.colors.gradients.darkA};
            }


            button.orange {
                background: linear-gradient(to right,${theme.colors.gradients.orangeA} 10%,${theme.colors.gradients.orangeB});
                color: ${theme.colors.base.white};
                border-color: ${theme.colors.gradients.orangeA};
            }

            button.outline.text-orange {
                color: ${theme.colors.gradients.orangeA};
            }


            button.red {
                background: linear-gradient(to right,${theme.colors.gradients.redA} 10%,${theme.colors.gradients.redB});
                color: ${theme.colors.base.white};
                border-color: ${theme.colors.gradients.redA};

            }

            button.outline.text-red {
                color: ${theme.colors.gradients.redA};
            }

            button.green {
                background: linear-gradient(to right,${theme.colors.gradients.greenA} 10%,${theme.colors.gradients.greenB});
                color: ${theme.colors.base.white};
                border-color: ${theme.colors.gradients.greenA};
            }

            button.outline.text-green {
                color: ${theme.colors.gradients.greenA};
            }


            button.blue {
                background: linear-gradient(to right,${theme.colors.gradients.blueA} 10%,${theme.colors.gradients.blueB});
                color: ${theme.colors.base.white};
                border-color: ${theme.colors.gradients.blueA};

            }

            button.outline.text-blue {
                color: ${theme.colors.gradients.blueA};
            }

            button.purple {
                background: linear-gradient(to right,${theme.colors.gradients.purpleA} 10%,${theme.colors.gradients.purpleB});
                color: ${theme.colors.base.white};
                border-color: ${theme.colors.gradients.purpleA};
            }

            button.outline.text-purple {
                color: ${theme.colors.gradients.purpleA};
            }


            button.outline {
                border-width: 2px;
                border-style: solid;
                box-sizing:border-box;
                background: transparent!important;
                color: ${theme.colors.base.base};
            }

            button.outline.transparent {
                border-width: 0px;
            }

            button:active {
                filter: brightness(90%);
            }

            button.disabled {
                background: linear-gradient(to right,${theme.colors.base.darkGray} 10%,${theme.colors.base.darkGray});
                color: ${theme.colors.base.lightGray};
            }

            @media(hover:hover) {
                button:hover {
                    ${props.disabled !== true ? `
                        filter: brightness(110%);
                  ` : ``}
                }
            }

        `}</style>
        <button ref={ref} className={`${props.type}  ${props.variant === 'transparent' ? ` 
        transparent outline text-${props.type}` : ''} 
        ${props.variant === 'outlined' ? ` outline text-${props.type}` : ''} 
        ${props.disabled ? ' disabled' : ''}`}

            type={props.submit ? 'submit' : 'button'}
            onClick={props.onClick}
            disabled={props.disabled}>
            {props.children}
        </button>
    </div>
))

export default React.forwardRef((props, ref) => (
    props.href ?
        <Link href={props.href} as={props.as || props.href}>
            <a>
                <style>{`
                    a {
                        width: 100%;
                    }
                `}</style>
                <Button {...props} ref={ref}>
                    {props.children}
                </Button>
            </a>
        </Link> :
        <Button {...props} ref={ref}>
            {props.children}
        </Button>

));