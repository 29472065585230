import React from "react"
import theme from "../../../../config/theme"
import Link from "../../../elements/Base/Link"

const Option = (props) => {
    return (
        <Link href={props.href || props.as} as={props.as}>
            <div className="option">
                <style jsx>{`
                    .option {
                        height: 48px;
                        padding: 10px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-weight: 700;
                        color: ${theme.colors.base.base};
                        border-bottom: 1px solid ${theme.colors.base.lightGray};
                    }

                    .option:hover {
                        background-color: ${theme.colors.base.secondary};
                        color: ${theme.colors.base.white};
                    }
                `}</style>
                {props.children}
            </div>
        </Link>
    )
}

export default Option;