import React from "react";
import Icon from "react-icons-kit";
import theme from "../../../config/theme";
import WidthWrapper from "../Base/WidthWrapper";

import { useRouter, withRouter } from "next/router";
import { readableSearchValue } from "../../../utils/misc";
import Link from "../Base/Link";
import StructuredData from "../StructuredDataElement";

import { home } from "react-icons-kit/fa/home";

const Breadcrumbs = (props) => {
  const router = useRouter();
  let additionalCrumbs = [];
  if (props.breadcrumbs) {
    additionalCrumbs = props.breadcrumbs.map((bc) =>
      bc.type === "category"
        ? {
            label: bc.title,
            as: "/categorie/" + bc.slug,
            href: "/categorie/[slug]",
          }
        : bc.type === "product"
        ? { label: bc.title, href: null, as: null }
        : bc
    );

    if (additionalCrumbs.length > 0) {
      additionalCrumbs[additionalCrumbs.length - 1].href = null;
    }
  }

  if (props.query) {
    additionalCrumbs = [{ label: readableSearchValue(props.query) }];
  }

  if (router.query && router.query.game) {
    additionalCrumbs = [
      {
        label:
          router.query.game == "3"
            ? "Yu-Gi-Oh! TCG"
            : router.query.game == "1"
            ? "Magic the Gathering"
            : router.query.game == "18"
            ? "One Piece Card Game"
            : router.query.game == "21"
            ? "Star Wars Unlimited"
            : "Pokémon TCG",
      },
    ];
  }

  let breadcrumbElements = {
    "/": null,
    "/toate-produsele": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Produse", href: "/toate-produsele", as: "/toate-produsele" },
      ...additionalCrumbs,
    ],
    "/categorie/[slug]": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Produse", href: "/toate-produsele", as: "/toate-produsele" },
      ...additionalCrumbs,
    ],
    "/cautare/[query]": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Produse", href: "/toate-produsele", as: "/toate-produsele" },
      { label: "Cautare" },
      ...additionalCrumbs,
    ],
    "/produs/[slug]": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Produse", href: "/toate-produsele", as: "/toate-produsele" },
      ...additionalCrumbs,
    ],
    "/cautare-singles/[query]": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Singles", href: "/singles", as: "/singles" },
      { label: "Cautare" },
    ],
    "/singles": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Singles", href: "/singles", as: "/singles" },
      ...additionalCrumbs,
    ],
    "/single/[id]": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Singles", href: "/singles", as: "/singles" },
      ...additionalCrumbs,
    ],
    "/info/contact": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Contact" },
    ],
    "/info/detalii-livrare": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Livrare și Plată" },
    ],
    "/info/politica-retur": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Politica de retur" },
    ],
    "/info/termeni-si-conditii": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Termeni și Condiții" },
    ],
    "/info/politica-utilizare-cookies": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Politica de utilizare Cookies" },
    ],
    "/info/politica-confidentialitate": [
      { label: "Acasa", href: "/", as: "/" },
      { label: "Politica de Confidențialitate" },
    ],
  };

  if (
    router.pathname === "/" ||
    (!breadcrumbElements[router.pathname] && additionalCrumbs.length === 0)
  ) {
    return null;
  }

  return (
    <>
      <style jsx>{`
        .breadcrumbs-wrapper {
          width: 100%;
          background-color: ${theme.colors.base.lightGray};
          color: ${theme.colors.base.base};
        }

        .breadcrumbs {
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          height: 32px;
          display: flex;
          margin-left: 10px;
        }

        .breadcrumb-element {
          height: 32px;
          font-size: 1em;
          display: flex;
          align-items: center;
          text-align: center;
          font-weight: 700;
        }

        .breadcrumb-element + .breadcrumb-element:before {
          padding: 8px;
          content: "›";
        }

        .muted {
          opacity: 0.8;
          font-weight: normal;
        }

        .muted:hover {
          opacity: 1;
        }
      `}</style>
      <div
        className="breadcrumbs-wrapper"
        key={"breadcrumbs-" + router.pathname}
      >
        <WidthWrapper>
          <StructuredData
            type="Breadcrumbs"
            breadcrumbs={breadcrumbElements[router.pathname]}
            endPath={router.pathname}
          />
          <div className="breadcrumbs">
            {breadcrumbElements[router.pathname].map((element, index) => {
              const computedClass =
                index === breadcrumbElements[router.pathname].length - 1
                  ? "hide-lt-m"
                  : index === 1 &&
                    breadcrumbElements[router.pathname].length > 3
                  ? "hide-lt-s"
                  : "";

              return (
                <div
                  className={`breadcrumb-element ${computedClass} ${
                    element.href ? "muted" : ""
                  }`}
                  key={`breadcrumb-${index}`}
                >
                  {element.href ? (
                    <Link href={element.href} as={element.as}>
                      {element.label === "Acasa" ? (
                        <Icon icon={home} />
                      ) : (
                        element.label
                      )}
                    </Link>
                  ) : (
                    <div className="leaf-element">{element.label}</div>
                  )}
                </div>
              );
            })}
          </div>
        </WidthWrapper>
      </div>
    </>
  );
};

export default withRouter(Breadcrumbs);
