export const friendlySearchValue = (value) => {
    return encodeURIComponent(value.replace(/ /gi, "\+").toLowerCase());
};

export const readableSearchValue = value => {
    return decodeURIComponent(value.replace(/\+/gi, " "));
}

export const splitPrice = (price) => {
    price = price || 0;
    const intPrice = Math.floor(price);
    let decPrice = Math.round((price - intPrice) * 100);

    if(decPrice.toString().length === 1) {
        decPrice = `0${decPrice}`;
    }

    return [intPrice, decPrice];
}
