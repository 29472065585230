import React from "react"
import Button from "../../../elements/Base/Button"
import { GlobalContext } from "../../../../contexts/GlobalContext"

const Account = (props) => {
    return (
        <div className="hamburger-account-wrapper">
            <style jsx>{`
                .hamburger-account-wrapper {
                    padding: 0px 10px;
                }
            `}</style>
            <GlobalContext.Consumer>
                {(GlobalContext) => (
                    !GlobalContext.authenticated ? 
                        <>
                            <Button href="/cont/login" type="secondary" shadow={false}>
                                Login
                            </Button>
                            <Button href="/cont/inregistrare" type="primary" variant="outlined" shadow={false}>
                                Cont nou
                            </Button>
                        </> : <>
                            <Button href="/cont/contul-meu" type="primary" shadow={false}>
                                Contul meu
                            </Button>
                            <Button type="secondary" 
                                variant="outlined" 
                                shadow={false}
                                onClick={GlobalContext.logout}>
                                Logout
                            </Button>
                        </>
                )}
            </GlobalContext.Consumer>
        </div>
    )
}

export default Account;