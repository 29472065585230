import React from "react"
import HTMLHead from '../components/blocks/HTMLHead';
import Header from '../components/blocks/Header';
import Footer from '../components/blocks/Footer';
import theme from '../config/theme';
import Breadcrumbs from '../components/elements/Breadcrumbs/Breadcrumbs';
import CookieConsent from "react-cookie-consent";
import InfoText from "../components/blocks/Homepage/InfoText";

import { FacebookProvider, CustomChat } from 'react-facebook';
import { updateConsent } from "../utils/analytics";


export default class MainLayout extends React.Component {
    render() {
        return (<>
                <div className="kritware-app-wrapper">
                    <style jsx>{`
                    .kritware-app-wrapper {
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                        width:  100%;
                        overflow-x: hidden;
                    }

                    main {
                        min-height: calc(100vh - 258px);
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                    }

                    @media only screen and (max-width: ${theme.responsive.m}px) {
                        main {
                            min-height: calc(100% - 50px);
                        }
                    }
                `}</style>
                        <HTMLHead/>
                        <nav>
                            <Header {...this.props}/>
                            <InfoText/>
                            <Breadcrumbs {...this.props}/>
                        </nav>
                        <main>
                            <FacebookProvider appId={process.env.facebookAuthId}>
                                <CustomChat pageId={116306670162695} minimized={false}/>
                            </FacebookProvider>
                            {this.props.children}
                        </main>
                        <footer>
                            <Footer/>
                        </footer>
                            <CookieConsent
                                location="bottom"
                                buttonText="ACCEPTA"
                                cookieName="kritCookieConsent"
                                style={{ background: theme.colors.gradients.blueA, fontWeight: "bold", fontFamily: "Poppins" }}
                                buttonStyle={{ background: theme.colors.base.white, fontFamily: "Poppins", color: theme.colors.gradients.blueA, fontWeight: "bold", fontSize: "13px", padding: "10px" }}
                                expires={150}
                                onAccept={() => {
                                    updateConsent();
                                }}
                                >
                            KRIT.ro folosește cookies pentru a permite buna funcționare a site-ului.
                        </CookieConsent>
                </div>


        </>)
    }
}
