const baseColors = {
    white: "#FFFFFF",
    lightGray: "#F4F4F4",
    gray: "#DBDBDB",
    darkGray: "#6A7580",
    black: "#141F29",

    base: "#1F2E3D",
    baseLight: "#33A0BD",
    baseDark: "#141F29",

    secondary: "#33A0BD",
    secondaryLight: "",
    secondaryDark: "",

    tertiary: "#FFD84F",
    green: "#628A38",

    accent: "#FF762B",
    
    accentLight: "",
    accentDark: "",
    
    error: "#FA4534",
    red: "#EF4509",

    googleButtonBackground: "#FFFFFF",
    facebookButtonBackground: "#4267b2"
}

const gradients = {
    darkA: "#141F29",
    darkB: "#1F2E3D",

    darkReverseA: "#141F29",
    darkReverseB: "#204961",

    redA: "#F22A27",
    redB: "#F0470A",

    orangeA: "#EF4509",
    orangeB: "#F27141",

    blueB: "#2CDBBB",
    blueA: "#3EAD9C",

    greenA: "#76C20C",
    greenB: "#B6CC0C",


    purpleA: "#8E22C2",
    purpleB: "#A635C2",

    grayA: "#69827C",
    grayB: "#78877A",

    yellowA: "#B5DE2F",
    yellowB: "#F5F03F"
}

//Global colors
const functionalColors = {
    background: baseColors.white,
    popupBackground: baseColors.white,

    text: baseColors.black,
    subText: baseColors.darkGray,

    promoPrice: baseColors.secondary,
    
    lightText: baseColors.white,
    lightSubText: baseColors.lightGray,
    
    errorText: baseColors.error,
}

//Component colors
const bodyColors = {
    background: functionalColors.background
}

const headerColors = {
    background: baseColors.black,
    text: functionalColors.lightText
}

const hamburgerMenuColors = {
    background: functionalColors.popupBackground,
    text: functionalColors.text,
    headerBackground: baseColors.base,
    headerText: functionalColors.lightText
}

const searchColors = {
    background: functionalColors.popupBackground,
    text: functionalColors.text,
    subtext: functionalColors.subtext,
    lightText: functionalColors.lightText,
    hoverItem: baseColors.lightGray,
    promoPrice: functionalColors.promoPrice
}

const buttonColors = {
    background: baseColors.darkGray,
    text: functionalColors.lightText,

    primaryBackground: baseColors.base,
    primaryBackgroundHover: baseColors.baseLight,
    onPrimaryBackground:functionalColors.lightText,

    secondaryBackground: baseColors.secondary,
    onSecondaryBackground: functionalColors.lightText,

    accentBackground: baseColors.accent,
    onAccentBackground: functionalColors.lightText,

    darkText: functionalColors.text,
    light: baseColors.white,

    googleButtonBackground: baseColors.googleButtonBackground,
    googleButtonBorder: baseColors.lightGray,
    googleButtonText: 'rgba(0, 0, 0, 54%)',

    facebookButtonBackground: baseColors.facebookButtonBackground,
    facebookButtontext: "#FFFFFF"
}

const headerLinksColors = {
    text: functionalColors.lightText,
    hover: baseColors.accent
}

const contentPanelColors = {
    background: baseColors.white,
    text: baseColors.black,
    shadow: baseColors.baseLight
}

const typographyColors = {
    title: baseColors.base,
    subtitle: baseColors.baseLight
}

const formColors = {
    text: baseColors.base,
    placeholderText: baseColors.baseLight,
    border: baseColors.baseLight,
    activeBorder: baseColors.accent,
    hoverBorder: baseColors.baseDark,
    separator: baseColors.lightGray
}

const popoverColors = {
    text: baseColors.base,
    background: baseColors.white,
    shadow: baseColors.baseLight
}

export default {
    gradients,
    base: baseColors,
    functional: functionalColors,
    body: bodyColors,
    typography: typographyColors,
    form: formColors,
    header: headerColors,
    hamburgerMenu: hamburgerMenuColors,
    search: searchColors,
    button: buttonColors,
    headerLinks: headerLinksColors,
    contentPanel: contentPanelColors,
    popover: popoverColors
}